import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { Fetch } from "../../types/fetch";

interface LoadingProps {
  isInline?: boolean;
  isOverlay?: boolean;
}

function Loading(props: LoadingProps): JSX.Element {
  return (
    <div
      role="alert"
      aria-busy="true"
      aria-live="polite"
      className={classnames({
        [styles.loadingPage]: !props.isInline,
        [styles.loadingInline]: props.isInline,
        [styles.loadingOverlay]: props.isOverlay
      })}
    >
      <div className={styles.loadingSpinner}>
        <span className={styles.hide}>Loading...</span>
        <div />
        <div />
      </div>
    </div>
  );
}

interface UninitializedProps {
  isInline: boolean;
  isOverlay?: boolean;
}

function UninitializedComponent(props: UninitializedProps): JSX.Element {
  return (
    <div role="alert" aria-busy="true" aria-live="polite">
      <div>
        <span>Uninitialized</span>
      </div>
    </div>
  );
}

interface FailureProps {
  isInline: boolean;
  isOverlay?: boolean;
  message: string;
}

function FailureComponent(props: FailureProps): JSX.Element {
  return (
    <div role="alert" aria-busy="true" aria-live="polite">
      <div>
        <span>Failure</span>
        <span>{props.message}</span>
      </div>
    </div>
  );
}

export function FetchComponent<T>(
  fetch: Fetch<T>,
  renderSuccess: (t: T) => JSX.Element
): JSX.Element {
  if (fetch.type === "loading") return <Loading isInline={true} />;

  if (fetch.type === "uninitialized")
    return <UninitializedComponent isInline={true} />;

  if (fetch.type === "failure")
    return <FailureComponent isInline={true} message={fetch.msg} />;

  return renderSuccess(fetch.data);
}

export default Loading;
