import React, { FunctionComponent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import Loading from "../Loading";
import { RouteComponentProps, withRouter } from "react-router";
import { getPort, updatePort } from "../../api/port";
import { Fetch, uninitialized } from "../../types/fetch";
import { getCompany } from "../../api/company";
import { Company, Port, PortInput, portName } from "../../types/company";
import Section from "../Page/Section";
import NodeMaterials from "../Material/NodeMaterials";
import Terminals from "../Terminal/Terminals";
import { nonEmpty } from "../../utils/stringutil";
import { showError } from "../../utils/error";

interface Params {
  companyId: string;
  companyPortId: string;
}

const EditPort: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId, companyPortId } = match.params;
  const { handleSubmit, control, watch } = useForm<Port>();

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [port, setPort] = useState<Fetch<Port>>(uninitialized);

  useEffect(() => {
    getCompany(companyId).then(setCompany);
    getPort(companyPortId).then(setPort);
  }, [companyId, companyPortId]);

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (company.type === "loading") {
    return <Loading />;
  }
  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }

  if (port.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (port.type === "loading") {
    return <Loading />;
  }
  if (port.type === "failure") {
    return <div>Ouch {port.msg}</div>;
  }

  function reloadPort(): void {
    getPort(companyPortId).then(p => setPort(p));
  }

  const onSubmit = (state: Port) => {
    let input: PortInput = {
      companyId: companyId,
      portId: port.data.portId
    };
    if (nonEmpty(state.displayName)) {
      input.displayName = state.displayName;
    }
    updatePort(companyPortId, input).then(p => {
      if (p.type === "success") {
        setPort(p);
      } else {
        showError(p.msg);
      }
    });
  };

  const returnToCompanyPage = () => {
    history.push(`/companies/${companyId}`);
  };

  const displayName = watch<"displayName", string>("displayName");

  const companyName: string = company.data.name;
  const isDisabled: boolean =
    displayName === undefined || displayName === port.data.displayName;

  return (
    <div className={styles.content}>
      <h1>
        Edit port {portName(port.data)} for company {companyName}
      </h1>
      <Section title={"Details"}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
          <label className={styles.label} htmlFor="portId">
            UN/LOCODE (read only)
          </label>
          <Controller
            as={<input type="text" disabled={true} className={styles.input} />}
            label="portId"
            name="portId"
            defaultValue={port.data.portId}
            control={control}
          />
          <label className={styles.label} htmlFor="displayName">
            Display name
          </label>
          <Controller
            as={<input type="text" className={styles.input} />}
            label="displayName"
            name="displayName"
            defaultValue={port.data.displayName}
            control={control}
          />
          <Button
            title="Update"
            type="submit"
            className={styles.inputButton}
            disabled={isDisabled}
          >
            Update
          </Button>
        </form>
      </Section>
      <Section title={"Terminals"}>
        <Terminals
          history={history}
          reload={reloadPort}
          terminals={port.data.terminals}
          companyId={companyId}
          companyPortId={companyPortId}
        />
      </Section>
      <Section title={"Materials"}>
        <NodeMaterials
          history={history}
          reload={reloadPort}
          nodeMaterials={port.data.materials}
          company={company.data}
          nodeType={"port"}
          ownerId={companyPortId}
          ownerName={port.data.name}
        />
      </Section>
      <br />
      <hr />
      <br />
      <form onSubmit={() => returnToCompanyPage()} className={styles.inputForm}>
        <Button title="Update" type="submit" className={styles.inputButton}>
          Back
        </Button>
      </form>
    </div>
  );
};

export default withRouter(EditPort);
