import React, { FunctionComponent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import Loading from "../Loading";
import { RouteComponentProps, withRouter } from "react-router";
import { getPort } from "../../api/port";
import { Fetch, uninitialized } from "../../types/fetch";
import {
  Company,
  Port,
  Terminal,
  CompanyTerminalId
} from "../../types/company";
import { createTerminal } from "../../api/terminal";
import { nonEmpty } from "../../utils/stringutil";
import { showError } from "../../utils/error";
import { getCompany } from "../../api/company";

interface Params {
  companyId: string;
  companyPortId: string;
}

const NewTerminal: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId, companyPortId } = match.params;
  const { handleSubmit, control, watch } = useForm<Terminal>();

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [port, setPort] = useState<Fetch<Port>>(uninitialized);

  useEffect(() => {
    getCompany(companyId).then(setCompany);
    getPort(companyPortId).then(setPort);
  }, [companyId, companyPortId]);

  const name = watch("name");

  const isDisabled: boolean = !nonEmpty(name);

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (company.type === "loading") {
    return <Loading />;
  }
  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }

  if (port.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (port.type === "loading") {
    return <Loading />;
  }
  if (port.type === "failure") {
    return <div>Ouch {port.msg}</div>;
  }

  const onSubmit = (state: Port) => {
    createTerminal({ companyPortId: companyPortId, name: state.name }).then(
      terminal => {
        if (terminal.type === "success") {
          goToEditTerminalPage(terminal.data.id);
        } else {
          showError(terminal.msg);
        }
      }
    );
  };

  const returnToPortPage = () => {
    history.push(`/port/edit/${companyId}/${companyPortId}`);
  };

  const goToEditTerminalPage = (id: CompanyTerminalId) => {
    history.push(`/terminal/edit/${companyId}/${companyPortId}/${id}`);
  };

  const portName: string = port.data.name;

  return (
    <div className={styles.content}>
      <h1>
        New terminal for port {portName} in company {company.data.name}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
        <label className={styles.label} htmlFor="name">
          Name*
        </label>
        <Controller
          as={<input type="text" className={styles.input} />}
          label="name"
          name="name"
          defaultValue=""
          control={control}
        />
        <Button className={styles.inputButton} onClick={returnToPortPage}>
          Cancel
        </Button>
        <Button
          type="submit"
          className={styles.inputButton}
          disabled={isDisabled}
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default withRouter(NewTerminal);
