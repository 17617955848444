export type DashboardId = string;

export default interface Dashboard {
  id: DashboardId;
  workspaceId: string;
  name: string;
}

export interface DashboardInput {
  workspaceId: string;
  name: string;
}

export interface CompanyDashboardInput {
  companyId: string;
  dashboardId: string;
}

export function dashboardName(dashboard: Dashboard): string {
  return `${dashboard.name} (${dashboard.workspaceId})`;
}
