import React from "react";
import styles from "./styles.module.scss";
import { Material } from "../../types/material";
import classnames from "classnames";
import iconPencil from "assets/icons/pencil.svg";
import { Link } from "react-router-dom";
import { CompanyId } from "../../types/company";

interface Props {
  materials: Material[];
  companyId: CompanyId;
}

const Materials = (props: Props) => {
  if (!props.materials) {
    return null;
  }

  const materials = props.materials.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  );

  return (
    <>
      <ul className={styles.innerRowHeader}>
        <li>Name</li>
        <li>Commodity</li>
        <li className={classnames(styles.actionColumn)}>Action</li>
      </ul>
      {materials.map((material, index) => (
        <ul key={index} className={styles.innerRow}>
          <li>{material.name}</li>
          <li>{material.commodity.name}</li>
          <li className={classnames(styles.actionColumn)}>
            <Link to={`/materials/edit/${props.companyId}/${material.id}`}>
              <img src={iconPencil} alt="" />
            </Link>
          </li>
        </ul>
      ))}
    </>
  );
};

export default Materials;
