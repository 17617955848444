import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";
import Loading from "components/Loading";
import AppError from "components/AppError";
import Header from "./Header";
import { User, UserId } from "../../types/user";
import {
  Fetch,
  uninitialized,
  loading as IsLoading,
  Fetched,
  success
} from "../../types/fetch";
import { getUsers, patchUser, UserUpdate } from "../../api/user";
import { toast } from "react-toastify";
import Row from "./Row";
import UserDetails from "./UserDetails";

export default function Users(): JSX.Element {
  let [searchValue, setSearchValue] = useState<string>("");
  let [users, setUsers] = useState<Fetch<User[]>>(uninitialized);
  let mounted = useRef<boolean>(false);

  useEffect(() => {
    mounted.current = true;

    setUsers(IsLoading);
    getUsers().then(users => {
      if (mounted.current) {
        setUsers(users);
      }
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  function updateSearchValue(event: ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.target.value);
  }

  function toastUserPatch(fetched: Fetched<void>) {
    if (fetched.type === "success")
      toast(`User updated successfully`, {
        type: "success",
        className: classnames(styles.cargoToast)
      });
    else if (fetched.type === "failure")
      toast(`User update failed`, {
        type: "error",
        className: classnames(styles.cargoToast)
      });
  }

  if (users.type === "failure") {
    // Show an error page for catched errors.
    return <AppError error={users.msg} />;
  }

  if (users.type === "loading") {
    return <Loading />;
  }

  if (users.type === "uninitialized") {
    return <></>;
  }

  const usersData = users.data;
  const updateUserField = (userId: UserId) => (user: UserUpdate): void => {
    patchUser(userId, user).then(toastUserPatch);

    const updatedUsers = usersData.map(u =>
      u.userid === userId ? { ...u, ...user } : u
    );
    setUsers(success(updatedUsers));
  };

  return (
    <>
      <div className={classnames(styles.subHeader)}>
        <input
          className={classnames(styles.search)}
          placeholder="Search users"
          name="userSearch"
          value={searchValue}
          onChange={updateSearchValue}
        />
        <Header />
      </div>
      <div className={classnames(styles.scrollableRows)}>
        {users.data
          .filter(matchesSearchString(searchValue))
          .sort(alphabeticallyBy(u => u.email))
          .map(user => (
            <Row key={user.userid} user={user} searchValue={searchValue}>
              <UserDetails
                user={user}
                onUserUpdate={updateUserField(user.userid)}
              />
            </Row>
          ))}
      </div>
    </>
  );
}

const alphabeticallyBy = <T,>(f: (i: T) => string) => (a: T, b: T): number =>
  f(a).localeCompare(f(b));

const matchesSearchString = (searchValue: string) => (user: User) =>
  [
    user.email,
    user.fullname,
    ...(user.companies ?? []).flatMap(c => [c.name, c.companyId])
  ].some(v => v.toLowerCase().includes(searchValue.toLowerCase()));
