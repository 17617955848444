import React from "react";
import styles from "../styles.module.scss";

const ChangeArrow = ({ change }) => {
  const color = order[change.to] < order[change.from] ? "red" : "green";

  return (
    <li className={styles.rowLabelArrow} style={{ color }}>
      →
    </li>
  );
};

export default ChangeArrow;

const order = {
  NONE: 0,
  VIEW: 1,
  EDIT: 2,
  SHARE: 3
};
