import React, { FunctionComponent, useState } from "react";
import Highlighter from "react-highlight-words";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { User } from "../../types/user";

interface Props {
  user: User;
  searchValue: string;
}

const Row: FunctionComponent<Props> = props => {
  const { user } = props;

  const [openRow, setOpenRow] = useState<boolean>(false);

  const companyText = user.companies
    ? user.companies.map(company => company.name).join(", ")
    : "";

  return (
    <div
      key={user.userid}
      className={openRow ? classnames(styles.rowOpen) : undefined}
      id={user.userid}
    >
      <ul
        className={classnames(styles.row)}
        onClick={() => setOpenRow(!openRow)}
      >
        <li>
          <Highlighter
            highlightClassName={classnames(styles.highlight)}
            searchWords={[props.searchValue]}
            autoEscape={true}
            textToHighlight={user.email}
          />
        </li>
        <li>
          <Highlighter
            highlightClassName={classnames(styles.highlight)}
            searchWords={[props.searchValue]}
            autoEscape={true}
            textToHighlight={user.fullname}
          />
        </li>
        <li>
          <Highlighter
            highlightClassName={classnames(styles.highlight)}
            searchWords={[props.searchValue]}
            autoEscape={true}
            textToHighlight={companyText}
          />
        </li>
        <li>{user.datecreated}</li>
      </ul>
      <i
        className={classnames(styles.lblToggle)}
        onClick={() => setOpenRow(!openRow)}
      />
      <div
        className={
          openRow
            ? classnames(styles.collapsibleContent, styles.collapsibleOpen)
            : classnames(styles.collapsibleContent)
        }
      >
        <div className={classnames(styles.contentInner)}>
          {openRow && props.children}
        </div>
      </div>
    </div>
  );
};

export default Row;
