import { Berth, CompanyBerthId, BerthInput } from "../types/company";
import axios from "axios";
import * as env from "../utils/env";
import { expect200 } from "./index";
import { Fetched } from "../types/fetch";
import { ReadEvent, WriteEvent } from "../instrumentation/analytics";

export function getBerth(id: CompanyBerthId): Promise<Fetched<Berth>> {
  return expect200(
    axios.get(`${env.apiPath()}/company/berths/${id}`),
    new ReadEvent("getBerth")
  );
}

export function createBerth(input: BerthInput): Promise<Fetched<Berth>> {
  return expect200(
    axios.post(`${env.apiPath()}/company/berths`, input),
    new WriteEvent("createBerth")
  );
}

export function updateBerth(
  id: CompanyBerthId,
  input: BerthInput
): Promise<Fetched<Berth>> {
  return expect200(
    axios.put(`${env.apiPath()}/company/berths/${id}`, input),
    new WriteEvent("updateBerth")
  );
}

export const deleteBerth = (id: CompanyBerthId): Promise<Fetched<void>> => {
  return expect200(
    axios.delete(`${env.apiPath()}/company/berths/${id}`),
    new WriteEvent("deleteBerth")
  );
};
