import React from "react";
import CompanyRow from "./CompanyRow";

const Rows = props => {
  if (!props.companies) return null;

  return props.companies.map(company => (
    <CompanyRow
      company={company}
      key={company.id}
      searchValue={props.searchValue}
    />
  ));
};

export default Rows;
