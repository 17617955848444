import React from "react";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { Router, Route } from "react-router";
import * as history from "utils/history";
import { reducer } from "./reducer";

// Redux store middleware.
const middleware = [thunk];

// Import and add a logger for actions in development.
if (process.env.NODE_ENV === "development") {
  middleware.push(
    require("redux-logger").createLogger({
      collapsed: true,
      timestamp: false,
      duration: true
    })
  );
}

// Create the global state.
const store = createStore(reducer, {}, compose(applyMiddleware(...middleware)));

// Dispatch an action to the store.
export const dispatch = action => {
  store.dispatch(action);
};

// Get the current global state.
export const getState = () => {
  return store.getState();
};

// Wrap a component in the global state context.
export const context = Component => (
  <Provider store={store}>
    <Router history={history.browserHistory}>
      <Route component={Component} />
    </Router>
  </Provider>
);
