import * as actions from "./actions";

export const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actions.err:
      return {
        error: action.payload.error
      };
    case actions.req:
      return {
        loading: true
      };
    case actions.res:
      return {
        data: action.payload.data
      };
    default:
      return state;
  }
};
