export const isLoading = state => {
  return Boolean(state.company.loading);
};

export const isError = state => {
  return Boolean(state.company.error);
};

export const company = state => {
  return state.company;
};

export const currentTerminal = (state, company, terminal) => {
  return state.company.ports.find(port => port.id === terminal);
};
