import axios from "axios";
import * as redux from "utils/redux";
import * as actions from "./actions";
import * as env from "utils/env";
import * as notificationCreators from "state/notifications/creators";

export const req = redux.action(actions.req, () => onReq);
export const create = redux.action(actions.create, () => onCreate);
export const createApi = redux.action(actions.create, () => onCreateApi);
export const companyUsersreq = redux.action(
  actions.req,
  () => onCompanyUsersReq
);
export const res = redux.action(actions.res);
export const createRes = redux.action(actions.createRes);
export const err = redux.action(actions.err);

const onReq = (action, dispatch) => {
  return axios
    .get(`${env.apiPath()}/cargo/users`)
    .then(response => dispatch(res({ data: response.data })))
    .catch(error => dispatch(err({ error })));
};

const onCompanyUsersReq = (action, dispatch) => {
  return axios
    .get(`${env.apiPath()}/cargo/companies/${action.payload}/users`)
    .then(response => dispatch(res({ data: response.data })))
    .catch(error => dispatch(err({ error })));
};

export const onCreate = (action, dispatch) => {
  return axios
    .post(
      `${env.apiPath()}/cargo/companies/${action.payload.companyId}/users`,
      { email: action.payload.email, name: action.payload.name }
    )
    .then(response => {
      dispatch(
        notificationCreators.showToast({
          toastType: "success",
          toastMessage: `User ${action.payload.name} created successfully`
        })
      );
    })
    .catch(error => {
      dispatch(err({ error }));
      dispatch(
        notificationCreators.showToast({
          toastType: "error",
          toastMessage: `Error creating user ${action.payload.name}.`
        })
      );
    });
};

export const onCreateApi = (action, dispatch) => {
  return axios
    .post(
      `${env.apiPath()}/cargo/companies/${action.payload.companyId}/users/api`,
      {
        email: action.payload.email,
        name: action.payload.name,
        clientId: action.payload.clientId
      }
    )
    .then(response => {
      dispatch(
        notificationCreators.showToast({
          toastType: "success",
          toastMessage: `API User ${action.payload.name} created successfully`
        })
      );
    })
    .catch(error => {
      dispatch(err({ error }));
      dispatch(
        notificationCreators.showToast({
          toastType: "error",
          toastMessage: `Error creating API user ${action.payload.name}: ${error.message}.`
        })
      );
    });
};
