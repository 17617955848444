import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  subtitle: string;
  tools?: unknown;
}

const Page: FunctionComponent<Props> = props => (
  <main className={styles.main}>
    <header className={styles.header}>
      <div>
        <h1>{props.title}</h1>
        <div className={styles.subtitle}>{props.subtitle}</div>
      </div>
      {props.tools}
    </header>
    {props.children}
  </main>
);

export default Page;
