import { Fetched } from "../types/fetch";
import axios from "axios";
import * as env from "../utils/env";
import { expect200 } from "./index";
import Dashboard, {
  CompanyDashboardInput,
  DashboardId
} from "../types/dashboard";
import { CompanyId } from "../types/company";
import { ReadEvent, WriteEvent } from "../instrumentation/analytics";

export const addDashboard = (
  companyId: CompanyId,
  dashboardId: DashboardId
): Promise<Fetched<void>> => {
  const input: CompanyDashboardInput = {
    companyId: companyId,
    dashboardId: dashboardId
  };
  return expect200(
    axios.post(`${env.apiPath()}/company/companydashboards`, input),
    new ReadEvent("addDashboard")
  );
};

export const removeDashboard = (
  companyId: CompanyId,
  dashboardId: DashboardId
): Promise<Fetched<void>> => {
  return expect200(
    axios.delete(
      `${env.apiPath()}/company/companydashboards/${companyId}/${dashboardId}`
    ),
    new WriteEvent("removeDashboard")
  );
};

export function syncDashboards(): Promise<Fetched<Dashboard[]>> {
  return expect200(
    axios.post(`${env.apiPath()}/cargo/analytics/sync`, {
      headers: { "Content-Type": "application/json" }
    }),
    new WriteEvent("syncDashboards")
  );
}
