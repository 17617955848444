import React, { FunctionComponent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import Loading from "../Loading";
import { RouteComponentProps, withRouter } from "react-router";
import { Fetch, uninitialized } from "../../types/fetch";
import { Berth, Company, Terminal } from "../../types/company";
import Section from "../Page/Section";
import { getTerminal } from "../../api/terminal";
import { nonEmpty } from "../../utils/stringutil";
import NodeMaterials from "../Material/NodeMaterials";
import { getBerth, updateBerth } from "../../api/berth";
import { showError } from "../../utils/error";
import { getCompany } from "../../api/company";
import { BerthId, GlobalBerth } from "../../types/globalberth";
import { OptionsType } from "react-select";
import Select from "../Select";
import { getGlobalBerths } from "../../api/globalberth";

interface Params {
  companyId: string;
  companyPortId: string;
  terminalId: string;
  berthId: string;
}

export type OptionType = {
  value: string;
  label: string;
  name: string;
};

export function berthOptions(
  globalBerths: GlobalBerth[]
): OptionsType<OptionType> {
  return globalBerths
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(p => ({
      value: p.berthId,
      label: p.name + " (" + p.berthId + ")",
      name: p.name
    }));
}

const EditBerth: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId, companyPortId, terminalId, berthId } = match.params;
  const { handleSubmit, control, watch, setValue } = useForm<Berth>();

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [terminal, setTerminal] = useState<Fetch<Terminal>>(uninitialized);
  const [globalBerths, setGlobalBerths] = useState<Fetch<GlobalBerth[]>>(
    uninitialized
  );
  const [berth, setBerth] = useState<Fetch<Berth>>(uninitialized);

  useEffect(() => {
    getCompany(companyId).then(setCompany);
    getTerminal(terminalId).then(setTerminal);
    getGlobalBerths(companyPortId).then(setGlobalBerths);
    getBerth(berthId).then(setBerth);
  }, [companyId, companyPortId, terminalId, berthId]);

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (company.type === "loading") {
    return <Loading />;
  }
  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }

  if (terminal.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (terminal.type === "loading") {
    return <Loading />;
  }
  if (terminal.type === "failure") {
    return <div>Ouch {terminal.msg}</div>;
  }

  if (globalBerths.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (globalBerths.type === "loading") {
    return <Loading />;
  }
  if (globalBerths.type === "failure") {
    return <div>Ouch {globalBerths.msg}</div>;
  }

  if (berth.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (berth.type === "loading") {
    return <Loading />;
  }
  if (berth.type === "failure") {
    return <div>Ouch {berth.msg}</div>;
  }

  function reloadBerth(): void {
    getBerth(berthId).then(setBerth);
  }

  const onSubmit = (state: Berth) => {
    updateBerth(berthId, {
      terminalId: terminalId,
      berthId: state.berthId,
      name: state.name
    }).then(b => {
      if (b.type === "success") {
        setBerth(b);
      } else {
        showError(b.msg);
      }
    });
  };

  const returnToTerminalPage = () => {
    history.push(`/terminal/edit/${companyId}/${companyPortId}/${terminalId}`);
  };

  const currentBerthId = watch<"berthId", BerthId>("berthId");
  const currentName = watch<"name", string>("name");

  const isDisabled: boolean =
    !nonEmpty(currentName) ||
    (currentName === berth.data.name && currentBerthId === berth.data.berthId);

  const thisBerthOptions = berthOptions(globalBerths.data);

  return (
    <div className={styles.content}>
      <h1>
        Edit berth {berth.data.name} for terminal {terminal.data.name} in
        company {company.data.name}
      </h1>
      <Section title={"Details"}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
          <label className={styles.label} htmlFor="berthId">
            Global berth* (contact support@cargovalue.com to configure)
          </label>
          <Controller
            render={({onChange, value, name, ref}) => (
              <Select
                className={styles.select}
                value={thisBerthOptions.filter(c => c.value === value)}
                name={name}
                onChange={(opt: OptionType) => {
                  onChange(opt.value);
                  setValue("name", opt.name);
                }}
                options={thisBerthOptions}
                inputRef={ref}
              />
            )}
            defaultValue={berth.data.berthId}
            key="value"
            name="berthId"
            control={control}
          />
          <label className={styles.label} htmlFor="name">
            (Company specific) name*
          </label>
          <Controller
            as={<input type="text" className={styles.input}/>}
            name="name"
            defaultValue={berth.data.name}
            control={control}
          />
          <Button
            title="Update"
            type="submit"
            className={styles.inputButton}
            disabled={isDisabled}
          >
            Update
          </Button>
        </form>
      </Section>
      <Section title={"Materials"}>
        <NodeMaterials
          history={history}
          reload={reloadBerth}
          nodeMaterials={berth.data.materials}
          company={company.data}
          portId={companyPortId}
          terminalId={terminalId}
          nodeType={"berth"}
          ownerId={berthId}
          ownerName={berth.data.name}
        />
      </Section>
      <br/>
      <hr/>
      <br/>
      <form
        onSubmit={() => returnToTerminalPage()}
        className={styles.inputForm}
      >
        <Button title="Update" type="submit" className={styles.inputButton}>
          Back
        </Button>
      </form>
    </div>
  );
};

export default withRouter(EditBerth);
