import React from "react";
import Loading from "components/Loading";
import AppError from "components/AppError";
import Rows from "./Rows";
import Header from "./Header";
import styles from "./styles.module.scss";
import classnames from "classnames";
import * as companiesSelectors from "state/companies/selectors";
import * as companySelectors from "state/company/selectors";
import * as companiesCreators from "state/companies/creators";
import * as redux from "utils/redux";

class Companies extends React.Component {
  state = { openRow: -1, searchValue: "" };

  componentDidMount() {
    this.props.companiesCreators.req();
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  updateSearchValue = event => {
    this.setState({ searchValue: event.target.value });
  };

  render() {
    if (this.state.error) {
      // Show an error page for catched errors.
      return <AppError error={this.state.error} />;
    }

    if (this.props.isLoading || !this.props.companies.data) {
      return <Loading isInline={true} />;
    }

    const { companies } = this.props;

    let filteredCompanies = companies.data.filter(company => {
      return (
        company.displayName
          .toLowerCase()
          .indexOf(this.state.searchValue.toLowerCase()) !== -1 ||
        company.id
          .toLowerCase()
          .indexOf(this.state.searchValue.toLowerCase()) !== -1
      );
    });

    filteredCompanies = filteredCompanies.sort((a, b) =>
      a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1
    );

    return (
      <div className={classnames(styles.content)}>
        <div className={classnames(styles.subHeader)}>
          <input
            className={classnames(styles.search)}
            placeholder="Search companies"
            name="companySearch"
            value={this.state.searchValue}
            onChange={this.updateSearchValue}
          />
          <Header />
        </div>
        <div className={classnames(styles.scrollableRows)}>
          <Rows
            companies={filteredCompanies}
            searchValue={this.state.searchValue}
          />
        </div>
      </div>
    );
  }
}

export default redux.connect(
  state => ({
    isLoading: companiesSelectors.isLoading(state),
    companies: companiesSelectors.companies(state),
    company: companySelectors.company(state)
  }),
  { companiesCreators }
)(Companies);
