import * as actions from "./actions";
import * as materialActions from "state/materials/actions";

export const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actions.err:
      return {
        ...state,
        error: action.payload.error
      };
    case actions.req:
      return {
        ...state,
        loading: true
      };
    case actions.res:
      return {
        ...action.payload.data
      };
    case actions.loading:
      return {
        loading: action.payload
      };
    case actions.addMaterialReq:
      return {
        ...state,
        loading: true
      };
    case actions.addMaterialRes:
      const newPorts = state.ports.map(port => {
        if (port.id === action.payload.portId) {
          port.materials.push(action.payload.material);
          return port;
        }
        return port;
      });

      return { ...state, ports: newPorts };
    case materialActions.newMaterial:
      return {};
    default:
      return state;
  }
};
