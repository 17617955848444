import { CompanyUser, Permission } from "../../types/user";
import React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";

interface Props {
  userPermissions: { user: CompanyUser; permission: Permission }[];
}

export default function PermissionComponent(props: Props) {
  const { userPermissions } = props;

  const editPermissions = userPermissions.filter(i => i.permission === "EDIT");
  const viewPermissions = userPermissions.filter(i => i.permission === "VIEW");

  interface PermissionsProps {
    permissions: { user: CompanyUser; permission: Permission }[];
  }

  function Permissions(props: PermissionsProps): JSX.Element {
    return (
      <>
        {props.permissions.map(({ user, permission }) => (
          <ul key={user.userid}>
            <li> {user.fullname} </li>
            <li className={classnames(styles.email)}> {user.email} </li>
          </ul>
        ))}
      </>
    );
  }

  return (
    <div className={classnames(styles.userPermissions)}>
      {editPermissions.length > 0 && (
        <div>
          <header>EDIT</header>
          <Permissions permissions={editPermissions} />
        </div>
      )}
      {viewPermissions.length > 0 && (
        <div>
          <header>VIEW</header>
          <Permissions permissions={viewPermissions} />
        </div>
      )}
    </div>
  );
}
