import React, { useState, useEffect, FunctionComponent } from "react";
import { useForm, Controller } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import Select from "../Select";
import Loading from "../Loading";
import { OptionsType } from "react-select";
import { RouteComponentProps, withRouter } from "react-router";
import { Fetch, uninitialized } from "../../types/fetch";
import { getCompany } from "../../api/company";
import { Company } from "../../types/company";
import {
  Inbound,
  InboundOrOutbound,
  materialName,
  NodeMaterial,
  Outbound
} from "../../types/material";
import {
  NodeType,
  NodeMaterialInput,
  nodeTypeAttributes,
  stringToFloat,
  stringToInboundOrOutbound
} from "./NodeMaterials";
import { combineToPath } from "../../utils/stringutil";

interface Params {
  companyId: string;
  portId?: string;
  terminalId?: string;
  ownerId: string;
  nodeType: NodeType;
  id?: string;
}

const NewEditNodeMaterial: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId, portId, terminalId, ownerId, nodeType, id } = match.params;
  const { register, handleSubmit, control, watch } = useForm<
    NodeMaterialInput
  >();

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [nodeMaterial, setNodeMaterial] = useState<Fetch<NodeMaterial>>(
    uninitialized
  );

  const nodeTypeAttribute = nodeTypeAttributes(nodeType);

  const editMode: boolean = id !== undefined;
  const readOnlyKeyFields = editMode && nodeType === "port";

  let maybeNodeMaterial: NodeMaterial | undefined = undefined;

  useEffect(() => {
    getCompany(companyId).then(setCompany);
    if (id !== undefined) {
      nodeTypeAttribute.get(id).then(setNodeMaterial);
    }
  }, [companyId, id, nodeTypeAttribute]);

  const materialId: string = watch("materialId");
  const inboundOrOutbound:
    | InboundOrOutbound
    | undefined = stringToInboundOrOutbound(watch("inboundOrOutbound"));
  const inboundOrOutboundNotSelected: boolean =
    watch("inboundOrOutbound") === null;

  const loadOrDischargeRate: number | undefined = stringToFloat(
    watch("loadOrDischargeRate")
  );

  function isDisabled(): boolean {
    return materialId == null || inboundOrOutboundNotSelected || !hasChanged();
  }

  function hasChanged(): boolean {
    if (maybeNodeMaterial === undefined) {
      return true;
    }
    return (
      materialId !== maybeNodeMaterial.material.id ||
      inboundOrOutbound !== maybeNodeMaterial.inboundOrOutbound ||
      loadOrDischargeRate !== maybeNodeMaterial.loadOrDischargeRate
    );
  }

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (company.type === "loading") {
    return <Loading />;
  }
  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }

  if (id !== undefined) {
    if (nodeMaterial.type === "uninitialized") {
      return <div>Initializing</div>;
    }
    if (nodeMaterial.type === "loading") {
      return <Loading />;
    }
    if (nodeMaterial.type === "failure") {
      return <div>Ouch {nodeMaterial.msg}</div>;
    }
    maybeNodeMaterial = nodeMaterial.data;
  }

  const onSubmit = (state: NodeMaterialInput) => {
    state.ownerId = ownerId;
    if (id !== undefined) {
      nodeTypeAttribute.update(id, state).then(r => {
        if (r.type === "success") {
          history.push(parentPath());
        } else {
          alert(r.msg);
        }
      });
    } else {
      nodeTypeAttribute.create(state).then(r => {
        if (r.type === "success") {
          history.push(parentPath());
        } else {
          alert(r.msg);
        }
      });
    }
  };

  const onCancel = () => {
    history.push(parentPath());
  };

  function parentPath(): string {
    return (
      nodeTypeAttributes(nodeType).materialParentPath +
      combineToPath([companyId, portId, terminalId, ownerId])
    );
  }

  type OptionEntry = {
    value: string;
    label: string;
  };

  const materialValues: OptionsType<OptionEntry> = company.data.materials
    .sort((a, b) => (materialName(a) > materialName(b) ? 1 : -1))
    .map(m => ({ value: m.id, label: materialName(m) }));

  const both: OptionEntry = {
    value: "inboundAndOutbound",
    label: "Inbound and outbound"
  };

  function inboundOrOutboundValues(): OptionsType<OptionEntry> {
    const values: OptionEntry[] = [
      { value: Inbound, label: "Inbound" },
      { value: Outbound, label: "Outbound" }
    ];
    return nodeTypeAttribute.mandatoryInboundOrOutbound
      ? values
      : values.concat([both]);
  }

  const context = company.data.name;
  return (
    <div className={styles.content}>
      <h1>
        {(editMode ? "Edit material for " : "New material for ") + context}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
        <label className={styles.label} htmlFor="materialId">
          Material*
        </label>
        <Controller
          render={({ onChange, value, name }) => (
            <Select
              isDisabled={readOnlyKeyFields}
              className={styles.select}
              value={materialValues.filter(c => c.value === value)}
              name={name}
              onChange={(opt: OptionEntry) => {
                onChange(opt.value);
              }}
              options={materialValues}
            />
          )}
          defaultValue={
            maybeNodeMaterial === undefined
              ? null
              : maybeNodeMaterial.material.id
          }
          key="materialId"
          label="materialId"
          name="materialId"
          control={control}
          ref={register}
        />
        <label className={styles.label} htmlFor="inboundOrOutbound">
          {nodeTypeAttribute.mandatoryInboundOrOutbound
            ? "Inbound or outbound*"
            : "Inbound or outbound"}
        </label>
        <Controller
          render={({ onChange, value, name }) => (
            <Select
              isDisabled={readOnlyKeyFields}
              className={styles.select}
              value={inboundOrOutboundValues().filter(c => c.value === value)}
              name={name}
              onChange={(opt: OptionEntry) => {
                onChange(opt.value);
              }}
              options={inboundOrOutboundValues()}
            />
          )}
          defaultValue={
            maybeNodeMaterial === undefined
              ? null
              : maybeNodeMaterial.inboundOrOutbound === null
              ? both.value
              : maybeNodeMaterial.inboundOrOutbound
          }
          key="inboundOrOutbound"
          label="inboundOrOutbound"
          name="inboundOrOutbound"
          control={control}
          ref={register}
        />

        {nodeTypeAttribute.hasLoadOrDischargeRate ? (
          <div>
            <label className={styles.label} htmlFor="loadOrDischargeRate">
              Load or discharge rate (MT/d)
            </label>
            <Controller
              as={<input type="number" step="0.1" className={styles.input} />}
              defaultValue={
                maybeNodeMaterial === undefined
                  ? ""
                  : maybeNodeMaterial.loadOrDischargeRate
              }
              label="loadOrDischargeRate"
              name="loadOrDischargeRate"
              control={control}
            />
          </div>
        ) : (
          ""
        )}
        <Button
          title="Cancel"
          className={styles.inputButton}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          title="Create"
          type="submit"
          className={styles.inputButton}
          disabled={isDisabled()}
        >
          Save
        </Button>
      </form>
    </div>
  );
};
export default withRouter(NewEditNodeMaterial);
