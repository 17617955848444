import React, {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useEffect,
  useState
} from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";
import formStyles from "../form.module.scss";
import { User, UserExtended } from "../../types/user";
import { Fetch, uninitialized } from "../../types/fetch";
import { getUser, UserUpdate } from "../../api/user";
import Loading from "../Loading";
import { CompanyId } from "../../types/company";
import { UserPermissions } from "./UserPermissions";
import { Button } from "../Button";

interface Props {
  user: User;
  onUserUpdate: (user: UserUpdate) => void;
}

const UserDetails: FunctionComponent<Props> = ({ user, onUserUpdate }) => {
  const [userExtended, setUserExtended] = useState<Fetch<UserExtended>>(
    uninitialized
  );

  const companyName = (companyId: CompanyId): string =>
    user.companies?.find(c => c.companyId === companyId)?.name ?? companyId;

  useEffect(() => {
    getUser(user.userid).then(setUserExtended);
  }, [user.userid]);

  const [formData, setFormData] = useState<UserUpdate>({
    fullname: user.fullname,
    email: user.email,
    admin: user.admin
  });

  const hasChanges = (["fullname", "email", "admin"] as const).some(
    f => formData[f] !== user[f]
  );

  if (userExtended.type === "loading") {
    return <Loading />;
  }

  if (userExtended.type === "uninitialized") {
    return <>Uninitialized</>;
  }

  if (userExtended.type === "failure") {
    return <>Problems loading content</>;
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    onUserUpdate(formData);
    event.preventDefault();
  };

  const onChange = (fieldName: keyof UserUpdate) => ({
    target
  }: ChangeEvent<HTMLInputElement>) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    setFormData(d => ({ ...d, [fieldName]: value }));
  };

  return (
    <>
      <form className={styles.form} onSubmit={onSubmit}>
        <fieldset className={styles.fieldset}>
          <legend>User details</legend>
          <label className={styles.label}>
            Full name
            <input
              type="text"
              className={formStyles.input}
              value={formData.fullname}
              onChange={onChange("fullname")}
            />
          </label>
          <label className={styles.label}>
            Email
            <input
              type="text"
              className={formStyles.input}
              value={formData.email}
              onChange={onChange("email")}
            />
          </label>
          <div className={styles.label}>
            Roles
            <label className={formStyles.input}>
              <input
                type="checkbox"
                className={formStyles.checkbox}
                checked={formData.admin}
                onChange={onChange("admin")}
              />
              Admin
            </label>
          </div>
        </fieldset>
        <Button type="submit" disabled={!hasChanges}>
          Save changes
        </Button>
      </form>
      <div className={classnames(styles.companiesRow)}>
        <label className={classnames(styles.label)}>Companies</label>
        <div className={classnames(styles.companiesList)}>
          {userExtended.data.cargo.companies.map((company, id) => (
            <div className={classnames(styles.companyContainer)} key={id}>
              {companyName(company.id)}
              <UserPermissions key={id} company={company} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UserDetails;
