import React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";

const Header = () => (
  <ul className={classnames(styles.row, styles.rowHeader)}>
    <li>Email</li>
    <li>Name</li>
    <li>Companies</li>
    <li>Date created</li>
  </ul>
);

export default Header;
