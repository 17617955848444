import React from "react";
import * as redux from "utils/redux";
import { withRouter } from "react-router";
import { Button } from "components/Button";
import Select from "components/Select";
import ConfirmPermissionsModal from "components/Modals/ConfirmPermissionsModal";

import * as permissionsSelectors from "state/permissions/selectors";
import * as permissionsCreators from "state/permissions/creators";
import * as usersSelectors from "state/users/selectors";
import * as usersCreators from "state/users/creators";
import * as companyCreators from "state/company/creators";
import * as companySelectors from "state/company/selectors";

import styles from "./styles.module.scss";
import Loading from "components/Loading";
import Page from "components/Page";
import Section from "components/Page/Section";
import PermissionList from "./PermissionList";
import alertIcon from "../../assets/icons/alert-circle-yellow.svg";

class Permissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modulesOpen: false,
      openTerminal: "",
      showConfirmPermissionsModal: false
    };
  }

  toggleModules = () => {
    const showModules = this.state.modulesOpen;
    this.setState({ modulesOpen: !showModules });
  };

  toggleTerminal = index => {
    const currentTerminal = this.state.openTerminal;
    if (currentTerminal === index) {
      this.setState({ openTerminal: "" });
    } else {
      this.setState({ openTerminal: index });
    }
  };

  setModulePermission = permission => {
    this.props.permissionsCreators.setModulePermission(permission);
  };

  setTerminalPermission = permission => {
    this.props.permissionsCreators.setTerminalPermission(permission);
  };

  setMaterialPermission = permission => {
    this.props.permissionsCreators.setMaterialPermission(permission);
  };

  componentDidMount = () => {
    const { params } = this.props.match;
    this.props.permissionsCreators.firstReq({
      companyId: params.companyId,
      userId: params.userId
    });

    this.props.usersCreators.companyUsersreq(params.companyId);

    if (!this.props.company.data) {
      this.props.companyCreators.req(params.companyId);
    }
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleSubmit = () => {
    this.setState({ showConfirmPermissionsModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showConfirmPermissionsModal: false });
  };

  handleUpdatePermissions = () => {
    this.props.permissionsCreators.update({
      companyId: this.props.match.params.companyId,
      userId: this.props.match.params.userId
    });

    this.props.history.goBack();
  };

  importPermissions = userId => {
    this.props.permissionsCreators.importFromUser({
      companyId: this.props.match.params.companyId,
      userId: decodeURI(userId)
    });
  };

  render() {
    const { permissions, users, company, match } = this.props;

    if (!permissions || !users || !company) {
      return <Loading />;
    }

    const differences = permissions.differences;
    const companyName = company && company.displayName;
    const userName = users.data
      ? users.data.find(user => user.userid === match.params.userId).fullname
      : null;

    const filteredTerminals = permissions.terminals.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    return (
      <Page
        title={`User permissions for ${companyName}`}
        subtitle={userName}
        tools={
          <div>
            <label htmlFor="selectPermissions" className={styles.label}>
              Import Port / Material permissions from
            </label>
            <Select
              options={
                users.data
                  ? users.data.map(user => {
                      return { value: user.userid, label: user.fullname };
                    })
                  : []
              }
              id="selectPermissions"
              className={styles.select}
              onChange={opt => this.importPermissions(opt.value)}
            />
          </div>
        }
      >
        <Section title={"Modules and Features"}>
          <span className={styles.alertWrapper}>
            <img src={alertIcon} alt="NB!"/>
            To assign Module permissions, use
            <a href="https://authorization.cargovalue.com"
               className={styles.link}>
              authorization.cargovalue.com
            </a>
          </span>
        </Section>
        <Section title={"Ports and Materials"}>
          <PermissionList
            permissions={filteredTerminals}
            toggleTerminal={this.toggleTerminal}
            openTerminal={this.state.openTerminal}
            setTerminalPermission={this.setTerminalPermission}
            setMaterialPermission={this.setMaterialPermission}
          />
        </Section>
        <div className={styles.saveButtons}>
          <Button
            title="Cancel"
            isSmaller
            isSecondary
            onClick={this.handleCancel}
            className={styles.saveButton}
          >
            Cancel
          </Button>
          <Button
            title="Save"
            isSmaller
            onClick={this.handleSubmit}
            className={styles.saveButton}
            disabled={differences === null}
          >
            Save
          </Button>
        </div>
        {differences !== null ? (
          <ConfirmPermissionsModal
            permissions={permissions.modules}
            handleCloseModal={this.handleCloseModal}
            terminals={permissions.terminals}
            showConfirmPermissionsModal={this.state.showConfirmPermissionsModal}
            company={companyName}
            user={userName}
            handleUpdatePermissions={this.handleUpdatePermissions}
            changes={differences}
          />
        ) : null}
      </Page>
    );
  }
}

export default withRouter(
  redux.connect(
    state => ({
      permissions: permissionsSelectors.permissions(state),
      users: usersSelectors.users(state),
      company: companySelectors.company(state)
    }),
    { permissionsCreators, usersCreators, companyCreators }
  )(Permissions)
);
