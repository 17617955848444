import React, { useState } from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { CompanyId } from "../../types/company";
import Dashboard, { dashboardName } from "../../types/dashboard";
import iconTrash from "../../assets/icons/trash.svg";
import ReactModal from "react-modal";
import { Button } from "../Button";
import { showError } from "../../utils/error";
import { removeDashboard } from "../../api/dashboard";

interface Props {
  reload: () => void;
  dashboards: Dashboard[];
  companyId: CompanyId;
}

const Dashboards = (props: Props) => {
  const [deletableDashboard, setDeletableDashboard] = useState<Dashboard>();

  if (!props.dashboards) {
    return null;
  }

  const dashboards = props.dashboards.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  );

  return (
    <>
      <ul className={styles.innerRowHeader}>
        <li>Name</li>
        <li>Workspace id</li>
        <li className={classnames(styles.actionColumn)}>Action</li>
      </ul>
      {dashboards.map((dashboard, index) => (
        <ul key={index} className={styles.innerRow}>
          <li>{dashboard.name}</li>
          <li>{dashboard.workspaceId}</li>
          <li className={classnames(styles.actionColumn)}>
            <img
              src={iconTrash}
              alt=""
              onClick={() => setDeletableDashboard(dashboard)}
            />

            {deletableDashboard && (
              <ReactModal
                isOpen={true}
                contentLabel="Delete"
                className={styles.modal}
                overlayClassName={styles.modalOverlay}
              >
                <p>
                  Are you sure you want to remove dashboard{" "}
                  {dashboardName(deletableDashboard)}?
                </p>
                <div>
                  <Button
                    className={classnames(styles.modalButton)}
                    onClick={() => setDeletableDashboard(undefined)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classnames(styles.modalButton)}
                    onClick={() =>
                      removeDashboard(
                        props.companyId,
                        deletableDashboard.id
                      ).then(b => {
                        if (b.type === "success") {
                          setDeletableDashboard(undefined);
                          props.reload();
                        } else {
                          showError(b.msg);
                        }
                      })
                    }
                  >
                    Delete
                  </Button>
                </div>
              </ReactModal>
            )}
          </li>
        </ul>
      ))}
    </>
  );
};

export default Dashboards;
