import React from "react";
import * as redux from "utils/redux";
import AppError from "components/AppError";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { Button } from "components/Button";
import { withRouter } from "react-router";
import * as usersCreators from "state/users/creators";
import * as usersSelectors from "state/users/selectors";

class NewUserApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", name: "", clientId: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = event => {
    const company = this.props.match.params.companyId;
    this.props.usersCreators.createApi({
      companyId: company,
      email: this.state.email,
      name: this.state.name,
      clientId: this.state.clientId
    });
    this.setState({ email: "", name: "", clientId: "" });
  };

  handleCancel = () => {
    this.props.history.push("/companies/" + this.props.match.params.companyId);
  };

  componentDidCatch(error) {
    this.setState({ error });
  }

  toggleRow = index => {
    const openRow = this.state.openRow;
    if (index === openRow) {
      this.setState({ openRow: null });
    } else {
      this.setState({ openRow: index });
    }
  };

  render() {
    if (this.state.error) {
      // Show an error page for catched errors.
      return <AppError error={this.state.error} />;
    }
    const { match } = this.props;

    return (
      <div className={classnames(styles.content)}>
        <div className={classnames(styles.inputForm)}>
          <div className={classnames(styles.header)}>
            Create new API user for {match.params.companyId}
          </div>
          <label className={classnames(styles.label)} htmlFor="email">
            Email*
          </label>
          <input
            id="email"
            name="email"
            type="text"
            className={classnames(styles.input)}
            value={this.state.email}
            onChange={this.handleChange}
          />
          <label className={classnames(styles.label)} htmlFor="name">
            Full name*
          </label>
          <input
            id="name"
            name="name"
            type="text"
            className={classnames(styles.input)}
            value={this.state.name}
            onChange={this.handleChange}
          />
          <label className={classnames(styles.label)} htmlFor="clientId">
            M2M Client ID*
          </label>
          <input
            id="clientId"
            name="clientId"
            type="text"
            className={classnames(styles.input)}
            value={this.state.clientId}
            onChange={this.handleChange}
          />
          <Button
            title="Back"
            className={classnames(styles.inputButton)}
            onClick={this.handleCancel}
          >
            Back
          </Button>
          <Button
            title="Create"
            className={classnames(styles.inputButton)}
            onClick={this.handleSubmit}
          >
            Create
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  redux.connect(
    state => ({
      users: usersSelectors.users(state)
    }),
    { usersCreators }
  )(NewUserApi)
);
