import axios from "axios";
import * as env from "utils/env";
import { Fetched } from "../types/fetch";
import { expect200 } from "./index";
import { ReadEvent } from "../instrumentation/analytics";

export function search(
  path?: string,
  method?: string,
  from?: string,
  to?: string,
  user?: string,
  params?: string,
  content?: string
): Promise<Fetched<AuditLogEntry[]>> {
  const paramsList: string[] = params?.split("&") || [];
  const paramsObject = paramsList
    .map(str => str.trim())
    .filter(str => str.indexOf("=") > 0)
    .map(str => {
      const obj: { [key: string]: string[] } = {};
      console.log(str);
      const [key, value] = str.split("=", 2);
      obj[key] = [value];
      return obj;
    })
    .reduce((previous, current) => {
      const obj: { [key: string]: string[] } = {};
      Reflect.ownKeys(previous)
        .concat(Reflect.ownKeys(current))
        .forEach(
          key =>
            (obj[key.toString()] = (previous[key.toString()] || []).concat(
              current[key.toString()] || []
            ))
        );
      return obj;
    }, {});

  const query = [
    path && path.trim().length > 0 && `path=${btoa(path.trim())}`,
    method && method.trim().length > 0 && `method=${method.trim()}`,
    from && from.trim().length > 0 && `from=${from.trim()}`,
    to && to.trim().length > 0 && `to=${to.trim()}`,
    user && user.trim().length > 0 && `user=${btoa(user.trim())}`,
    params &&
      params.trim().length > 0 &&
      `params=${btoa(JSON.stringify(paramsObject))}`,
    content && content.trim().length > 0 && `data=${btoa(content.trim())}`
  ]
    .filter(i => i !== undefined)
    .join("&");
  return expect200(
    axios.get<AuditLogEntry[]>(`${env.apiPath()}/auditlog?${query}`),
    new ReadEvent("queryAuditlog")
  );
}
