import React, { FunctionComponent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import { RouteComponentProps, withRouter } from "react-router";
import { Fetch, uninitialized } from "../../types/fetch";
import { showError } from "../../utils/error";
import Dashboard, { DashboardId, dashboardName } from "../../types/dashboard";
import Select from "../Select";
import { addDashboard, syncDashboards } from "../../api/dashboard";
import Loading from "../Loading";
import { Company } from "../../types/company";
import { getCompany } from "../../api/company";
import { OptionsType } from "react-select";

interface Params {
  companyId: string;
}

const AddDashboard: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId } = match.params;
  const { handleSubmit, control, watch } = useForm<Dashboard>();
  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [dashboards, setDashboards] = useState<Fetch<Dashboard[]>>(
    uninitialized
  );

  useEffect(() => {
    getCompany(companyId).then(setCompany);
    syncDashboards().then(setDashboards);
  }, [companyId]);

  const onSubmit = (state: Dashboard) => {
    addDashboard(companyId, dashboardId).then(dashboard => {
      if (dashboard.type === "success") {
        returnToCompanyPage();
      } else {
        showError(dashboard.msg);
      }
    });
  };

  const returnToCompanyPage = () => {
    history.push(`/companies/${companyId}`);
  };

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (company.type === "loading") {
    return <Loading />;
  }
  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }

  if (dashboards.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (dashboards.type === "loading") {
    return <Loading />;
  }
  if (dashboards.type === "failure") {
    return <div>Ouch {dashboards.msg}</div>;
  }

  const dashboardId: DashboardId = watch("dashboardId");

  function isDisabled(): boolean {
    console.log(dashboardId);
    return dashboardId === undefined;
  }

  type OptionEntry = {
    value: string;
    label: string;
  };

  const dashboardValues: OptionsType<OptionEntry> = dashboards.data
    .filter(d => !company.data.dashboards.map(d2 => d2.id).includes(d.id))
    .sort((a, b) => (dashboardName(a) > dashboardName(b) ? 1 : -1))
    .map(d => ({ value: d.id, label: dashboardName(d) }));

  return (
    <div className={styles.content}>
      <h1>Add dashboard</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
        <label className={styles.label} htmlFor="dashboardId">
          Dashboard*
        </label>
        <Controller
          render={({ onChange, value, name }) => (
            <Select
              className={styles.select}
              name={name}
              onChange={(opt: OptionEntry) => {
                onChange(opt.value);
              }}
              options={dashboardValues}
            />
          )}
          defaultValue={null}
          key="dashboardId"
          label="dashboardId"
          name="dashboardId"
          control={control}
        />
        <Button className={styles.inputButton} onClick={returnToCompanyPage}>
          Cancel
        </Button>
        <Button
          type="submit"
          className={styles.inputButton}
          disabled={isDisabled()}
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default withRouter(AddDashboard);
