import React, { useState } from "react";
import styles from "./styles.module.scss";
import formStyles from "./../form.module.scss";
import {
  BerthMaterialInput,
  defaultInboundOrOutbound,
  Inbound,
  InboundOrOutbound,
  inboundOutboundName,
  materialName,
  NodeMaterial,
  Outbound,
  PortMaterialInput,
  TerminalMaterialInput
} from "../../types/material";
import classnames from "classnames";
import iconPencil from "assets/icons/pencil.svg";
import { Link } from "react-router-dom";
import { Company, CompanyTerminalId } from "../../types/company";
import { Button } from "../Button";

import * as H from "history";
import {
  createBerthMaterial,
  createPortMaterial,
  createTerminalMaterial,
  deleteBerthMaterial,
  deletePortMaterial,
  deleteTerminalMaterial,
  getBerthMaterial,
  getPortMaterial,
  getTerminalMaterial,
  updateBerthMaterial,
  updatePortMaterial,
  updateTerminalMaterial
} from "../../api/material";
import { Fetched } from "../../types/fetch";
import ReactModal from "react-modal";
import iconTrash from "../../assets/icons/trash.svg";
import { PortId } from "../../types/globalport";
import { combineToPath } from "../../utils/stringutil";
import { compose } from "redux";
import { showError } from "../../utils/error";

export type NodeType = "port" | "terminal" | "berth";

export interface NodeMaterialInput {
  ownerId: string;
  materialId: string;
  inboundOrOutbound?: string;
  loadOrDischargeRate?: string;
}

interface NodeTypeAttributes {
  nodeType: NodeType;
  mandatoryInboundOrOutbound: boolean;
  hasLoadOrDischargeRate: boolean;
  editorPath: string;
  materialParentPath: string;
  get: (id: string) => Promise<Fetched<NodeMaterial>>;
  create: (nodeMaterialInput: NodeMaterialInput) => Promise<Fetched<void>>;
  update: (
    id: string,
    nodeMaterialInput: NodeMaterialInput
  ) => Promise<Fetched<void>>;
  delete: (id: string) => Promise<Fetched<void>>;
}

const nodeTypes: NodeTypeAttributes[] = [
  {
    nodeType: "port",
    mandatoryInboundOrOutbound: true,
    hasLoadOrDischargeRate: true,
    editorPath: "/portmaterial",
    materialParentPath: "/port/edit",
    get: getPortMaterial,
    create: compose(createPortMaterial, toPortMaterialInput),
    update: (id, nodeMaterialInput) => {
      return updatePortMaterial(id, toPortMaterialInput(nodeMaterialInput));
    },
    delete: deletePortMaterial
  },
  {
    nodeType: "terminal",
    mandatoryInboundOrOutbound: false,
    hasLoadOrDischargeRate: true,
    editorPath: "/terminalmaterial",
    materialParentPath: "/terminal/edit",
    get: getTerminalMaterial,
    create: compose(createTerminalMaterial, toTerminalMaterialInput),
    update: (id, nodeMaterialInput) => {
      return updateTerminalMaterial(
        id,
        toTerminalMaterialInput(nodeMaterialInput)
      );
    },
    delete: deleteTerminalMaterial
  },
  {
    nodeType: "berth",
    mandatoryInboundOrOutbound: false,
    hasLoadOrDischargeRate: true,
    editorPath: "/berthmaterial",
    materialParentPath: "/berth/edit",
    get: getBerthMaterial,
    create: compose(createBerthMaterial, toBerthMaterialInput),
    update: (id, nodeMaterialInput) => {
      return updateBerthMaterial(id, toBerthMaterialInput(nodeMaterialInput));
    },
    delete: deleteBerthMaterial
  }
];

function toPortMaterialInput(
  nodeMaterialInput: NodeMaterialInput
): PortMaterialInput {
  let inboundOrOutbound = stringToInboundOrOutbound(
    nodeMaterialInput.inboundOrOutbound
  );
  let loadOrDischargeRate = stringToFloat(
    nodeMaterialInput.loadOrDischargeRate
  );
  let result: PortMaterialInput = {
    companyPortId: nodeMaterialInput.ownerId,
    materialId: nodeMaterialInput.materialId,
    inboundOrOutbound:
      inboundOrOutbound === undefined
        ? defaultInboundOrOutbound
        : inboundOrOutbound
  };
  if (loadOrDischargeRate !== undefined) {
    result.loadOrDischargeRate = loadOrDischargeRate;
  }
  return result;
}

function toTerminalMaterialInput(
  nodeMaterialInput: NodeMaterialInput
): TerminalMaterialInput {
  let inboundOrOutbound = stringToInboundOrOutbound(
    nodeMaterialInput.inboundOrOutbound
  );
  let loadOrDischargeRate = stringToFloat(
    nodeMaterialInput.loadOrDischargeRate
  );
  let result: TerminalMaterialInput = {
    terminalId: nodeMaterialInput.ownerId,
    materialId: nodeMaterialInput.materialId
  };
  if (inboundOrOutbound !== undefined) {
    result.inboundOrOutbound = inboundOrOutbound;
  }
  if (loadOrDischargeRate !== undefined) {
    result.loadOrDischargeRate = loadOrDischargeRate;
  }
  return result;
}

function toBerthMaterialInput(
  nodeMaterialInput: NodeMaterialInput
): BerthMaterialInput {
  let inboundOrOutbound = stringToInboundOrOutbound(
    nodeMaterialInput.inboundOrOutbound
  );
  let loadOrDischargeRate = stringToFloat(
    nodeMaterialInput.loadOrDischargeRate
  );
  let result: BerthMaterialInput = {
    berthId: nodeMaterialInput.ownerId,
    materialId: nodeMaterialInput.materialId
  };
  if (inboundOrOutbound !== undefined) {
    result.inboundOrOutbound = inboundOrOutbound;
  }
  if (loadOrDischargeRate !== undefined) {
    result.loadOrDischargeRate = loadOrDischargeRate;
  }
  return result;
}

export function stringToFloat(s: string | undefined): number | undefined {
  if (s === undefined) {
    return undefined;
  }
  return parseFloat(s);
}

export function stringToInboundOrOutbound(
  s: string | undefined
): InboundOrOutbound | undefined {
  if (s === Inbound) {
    return Inbound;
  }
  if (s === Outbound) {
    return Outbound;
  }
  return undefined;
}

export function nodeTypeAttributes(nodeType: NodeType): NodeTypeAttributes {
  return nodeTypes.filter(nt => nt.nodeType === nodeType)[0];
}

interface Props {
  history: H.History;
  reload: () => void;
  nodeMaterials: NodeMaterial[];
  company: Company;
  portId?: PortId;
  terminalId?: CompanyTerminalId;
  nodeType: NodeType;
  ownerId: string;
  ownerName: String;
}
const NodeMaterials = (props: Props) => {
  const [deletableMaterial, setDeletableMaterial] = useState<NodeMaterial>();

  if (!props.nodeMaterials) {
    return null;
  }

  const nodeMaterials = props.nodeMaterials.sort((a, b) =>
    a.material.name.toLowerCase() > b.material.name.toLowerCase() ? 1 : -1
  );

  const nodeTypeAttribute = nodeTypeAttributes(props.nodeType);

  const addMaterialPage = () => {
    props.history.push(
      nodeTypeAttribute.editorPath +
        "/new" +
        combineToPath([
          props.nodeType,
          props.company.id,
          props.portId,
          props.terminalId,
          props.ownerId
        ])
    );
  };
  const deleteContext =
    " from " +
    props.nodeType +
    " " +
    props.ownerName +
    " in company " +
    props.company.name;
  return (
    <>
      <ul className={styles.innerRowHeader}>
        <li>Material</li>
        <li>Inbound or outbound</li>
        {nodeTypeAttribute.hasLoadOrDischargeRate ? (
          <li>Load or discharge rate (MT/d)</li>
        ) : (
          ""
        )}
        <li className={classnames(styles.actionColumn)}>Action</li>
      </ul>
      {nodeMaterials.map((nodeMaterial, index) => (
        <ul key={index} className={classnames(styles.innerRow)}>
          <li>{materialName(nodeMaterial.material)}</li>
          <li>{inboundOutboundName(nodeMaterial.inboundOrOutbound)}</li>
          {nodeTypeAttribute.hasLoadOrDischargeRate ? (
            <li>{nodeMaterial.loadOrDischargeRate}</li>
          ) : (
            ""
          )}
          <li className={classnames(styles.actionColumn)}>
            <Link
              to={
                nodeTypeAttribute.editorPath +
                "/edit" +
                combineToPath([
                  props.nodeType,
                  props.company.id,
                  props.portId,
                  props.terminalId,
                  props.ownerId,
                  nodeMaterial.id
                ])
              }
            >
              <img src={iconPencil} alt="" />
            </Link>
            <img
              src={iconTrash}
              alt=""
              onClick={() => setDeletableMaterial(nodeMaterial)}
            />

            {deletableMaterial && (
              <ReactModal
                isOpen={true}
                contentLabel="Delete"
                className={styles.modal}
                overlayClassName={styles.modalOverlay}
              >
                <p>
                  Are you sure you want to delete material{" "}
                  {materialName(deletableMaterial.material)}
                  {deleteContext}?
                </p>
                <div>
                  <Button
                    className={classnames(styles.modalButton)}
                    onClick={() => setDeletableMaterial(undefined)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classnames(styles.modalButton)}
                    onClick={() =>
                      nodeTypeAttribute.delete(deletableMaterial.id).then(m => {
                        if (m.type === "success") {
                          setDeletableMaterial(undefined);
                          props.reload();
                        } else {
                          showError(m.msg);
                        }
                      })
                    }
                  >
                    Delete
                  </Button>
                </div>
              </ReactModal>
            )}
          </li>
        </ul>
      ))}
      <br />
      <form onSubmit={() => addMaterialPage()} className={formStyles.inputForm}>
        <Button type="submit" className={formStyles.inputButton}>
          Add material
        </Button>
      </form>
    </>
  );
};

export default NodeMaterials;
