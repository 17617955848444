import React, { FunctionComponent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import Loading from "../Loading";
import { RouteComponentProps, withRouter } from "react-router";
import { Fetch, uninitialized } from "../../types/fetch";
import { Berth, Terminal, CompanyBerthId, Company } from "../../types/company";
import { createBerth } from "../../api/berth";
import { getTerminal } from "../../api/terminal";
import { nonEmpty } from "../../utils/stringutil";
import { showError } from "../../utils/error";
import { getCompany } from "../../api/company";
import { GlobalBerth } from "../../types/globalberth";
import { getGlobalBerths } from "../../api/globalberth";
import Select from "../Select";
import { berthOptions, OptionType } from "./EditBerth";

interface Params {
  companyId: string;
  companyPortId: string;
  terminalId: string;
}

const NewBerth: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId, companyPortId, terminalId } = match.params;
  const { register, handleSubmit, control, watch, setValue } = useForm<Berth>();

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [terminal, setTerminal] = useState<Fetch<Terminal>>(uninitialized);
  const [globalBerths, setGlobalBerths] = useState<Fetch<GlobalBerth[]>>(
    uninitialized
  );

  useEffect(() => {
    getCompany(companyId).then(setCompany);
    getTerminal(terminalId).then(setTerminal);
    getGlobalBerths(companyPortId).then(setGlobalBerths);
  }, [companyId, companyPortId, terminalId]);

  const name = watch("name");

  const isDisabled: boolean = !nonEmpty(name);

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (company.type === "loading") {
    return <Loading />;
  }
  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }

  if (terminal.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (terminal.type === "loading") {
    return <Loading />;
  }
  if (terminal.type === "failure") {
    return <div>Ouch {terminal.msg}</div>;
  }

  if (globalBerths.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (globalBerths.type === "loading") {
    return <Loading />;
  }
  if (globalBerths.type === "failure") {
    return <div>Ouch {globalBerths.msg}</div>;
  }

  const onSubmit = (state: Berth) => {
    createBerth({
      terminalId: terminalId,
      berthId: state.berthId,
      name: state.name
    }).then(berth => {
      if (berth.type === "success") {
        goToEditBerthPage(berth.data.id);
      } else {
        showError(berth.msg);
      }
    });
  };

  const returnToTerminalPage = () => {
    history.push(`/terminal/edit/${companyId}/${companyPortId}/${terminalId}`);
  };

  const goToEditBerthPage = (id: CompanyBerthId) => {
    history.push(
      `/berth/edit/${companyId}/${companyPortId}/${terminalId}/${id}`
    );
  };

  const terminalName: string = terminal.data.name;

  const thisBerthOptions = berthOptions(globalBerths.data);

  return (
    <div className={styles.content}>
      <h1>
        New berth for terminal {terminalName} in company {company.data.name}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
        <label className={styles.label} htmlFor="berthId">
          Global berth* (contact support@cargovalue.com to configure)
        </label>
        <Controller
          render={({ onChange, value, name, ref }) => (
            <Select
              className={styles.select}
              value={thisBerthOptions.filter(c => c.value === value)}
              name={name}
              onChange={(opt: OptionType) => {
                onChange(opt.value);
                setValue("name", opt.name);
              }}
              options={thisBerthOptions}
              inputRef={ref}
            />
          )}
          key="value"
          name="berthId"
          control={control}
        />
        <label className={styles.label} htmlFor="name">
          (Company specific) name*
        </label>
        <Controller
          as={<input type="text" className={styles.input} ref={register} />}
          label="name"
          name="name"
          control={control}
          ref={register}
        />
        <Button className={styles.inputButton} onClick={returnToTerminalPage}>
          Cancel
        </Button>
        <Button
          type="submit"
          className={styles.inputButton}
          disabled={isDisabled}
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default withRouter(NewBerth);
