import React from "react";

class AppScroll extends React.Component {
  componentDidMount() {
    // Scroll to the top when the app is first launched.
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    // Scroll to the top whenever the url changes,
    // unless there's a hash fragment in the new url.
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default AppScroll;
