import {
  CompanyBerthId,
  CompanyId,
  CompanyPortId,
  CompanyTerminalId
} from "./company";

export type CommodityId = string;
export type MaterialId = string;
export type PortMaterialId = string;
export type TerminalMaterialId = string;
export type BerthMaterialId = string;

export interface Commodity {
  id: CommodityId;
  name: string;
}

export interface Material {
  id: MaterialId;
  name: string;
  commodity: Commodity;
}

export function materialName(material: Material): string {
  if (material.name === material.commodity.name) {
    return material.name;
  }
  return `${material.name} (${material.commodity.name})`;
}

export interface NodeMaterial {
  id: string;
  material: Material;
  inboundOrOutbound?: InboundOrOutbound;
  loadOrDischargeRate?: LoadOrDischargeRate;
}

export interface PortMaterial extends NodeMaterial {
  id: PortMaterialId;
  material: Material;
  inboundOrOutbound: InboundOrOutbound;
  loadOrDischargeRate?: LoadOrDischargeRate;
}

export interface TerminalMaterial extends NodeMaterial {
  id: TerminalMaterialId;
  material: Material;
  inboundOrOutbound?: InboundOrOutbound;
  loadOrDischargeRate?: LoadOrDischargeRate;
}

export interface BerthMaterial extends NodeMaterial {
  id: BerthMaterialId;
  material: Material;
  inboundOrOutbound?: InboundOrOutbound;
  loadOrDischargeRate?: LoadOrDischargeRate;
}

export const Inbound: "inbound" = "inbound";
export const Outbound: "outbound" = "outbound";
export type InboundOrOutbound = typeof Inbound | typeof Outbound;
export const defaultInboundOrOutbound: InboundOrOutbound = Inbound;

export function inboundOutboundName(
  inboundOrOutbound?: InboundOrOutbound
): string {
  if (inboundOrOutbound === Inbound) {
    return "Inbound";
  }
  if (inboundOrOutbound === Outbound) {
    return "Outbound";
  }
  return "Inbound and outbound";
}

export type LoadOrDischargeRate = number;

export interface MaterialInput {
  companyId: CompanyId;
  commodityId: CommodityId;
  name: string;
}

export interface PortMaterialInput {
  companyPortId: CompanyPortId;
  materialId: MaterialId;
  inboundOrOutbound: InboundOrOutbound;
  loadOrDischargeRate?: LoadOrDischargeRate;
}

export interface TerminalMaterialInput {
  terminalId: CompanyTerminalId;
  materialId: MaterialId;
  inboundOrOutbound?: InboundOrOutbound;
  loadOrDischargeRate?: LoadOrDischargeRate;
}

export interface BerthMaterialInput {
  berthId: CompanyBerthId;
  materialId: MaterialId;
  inboundOrOutbound?: InboundOrOutbound;
  loadOrDischargeRate?: LoadOrDischargeRate;
}
