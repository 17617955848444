export const loading = "permissions/loading";
export const err = "permissions/err";
export const triggerImport = "permissions/triggerImport";
export const finishImport = "permissions/finishImport";
export const firstReq = "permissions/firstReq";
export const res = "permissions/res";
export const update = "permissions/update";
export const setModulePermission = "permissions/setModulePermission";
export const setTerminalPermission = "permissions/setTerminalPermission";
export const setMaterialPermission = "permissions/setMaterialPermission";
export const importPermissions = "permissions/importPermissions";
