import classnames from "classnames";
import styles from "./styles.module.scss";
import React from "react";
import { Permissions, Terminals } from "../../types/user";

interface Props {
  company: Permissions & Terminals;
}

export const UserPermissions = ({ company }: Props) => {
  return (
    <div className={classnames(styles.userPermissions)}>
      {company.permissions.length > 0 && (
        <div>
          <header>Company permissions</header>
          {company.permissions.map((permission, id) => (
            <div key={id}>
              <span className={classnames(styles.permission)}>
                {" "}
                {permission.permission}{" "}
              </span>
              <span>{permission.name}</span>
            </div>
          ))}
        </div>
      )}
      <div className={classnames(styles.terminals)}>
        <header>Ports</header>
        {company.terminals.map((terminal, id) => (
          <div key={id}>
            <div>{terminal.name}</div>
            {terminal.permissions.length > 0 && (
              <div>
                <header>Permissions</header>
                {terminal.permissions.map((permission, id) => (
                  <div key={id}>
                    <span className={classnames(styles.permission)}>
                      {" "}
                      {permission.permission}
                    </span>
                    <span>{permission.name}</span>
                  </div>
                ))}
              </div>
            )}
            <div className={classnames(styles.materials)}>
              <header>Materials</header>
              {terminal.materials
                .sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                )
                .map((material, id) => (
                  <div key={id}>
                    <div>
                      {material.name}
                      <span className={classnames(styles.inboundOutbound)}>
                        {" "}
                        {material.inboundOrOutbound}
                      </span>
                    </div>
                    {material.inventory.permission !== "NONE" && (
                      <div>
                        <span className={classnames(styles.permission)}>
                          {material.inventory.permission}{" "}
                        </span>{" "}
                        Inventory
                      </div>
                    )}
                    {material.schedule.permission !== "NONE" && (
                      <div>
                        <span className={classnames(styles.permission)}>
                          {material.schedule.permission}{" "}
                        </span>{" "}
                        Schedule
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
