export const isLoading = state => {
  return Boolean(state.users.loading);
};

export const isError = state => {
  return Boolean(state.users.error);
};

export const users = state => {
  return state.users;
};
