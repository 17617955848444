import axios from "axios";
import * as env from "utils/env";
import { Commodity } from "../types/material";
import { Fetched } from "../types/fetch";
import { expect200 } from "./index";
import { ReadEvent } from "../instrumentation/analytics";

export function fetchCommodities(): Promise<Fetched<Commodity[]>> {
  return expect200(
    axios.get<Commodity[]>(`${env.apiPath()}/cargo/commodities`),
    new ReadEvent("fetchCommodities")
  );
}
