import React from "react";
import { Switch, Route } from "react-router";
import { ToastContainer, toast, Slide } from "react-toastify";

import Header from "components/Header";
import Loading from "components/Loading";
import AppError from "components/AppError";
import AppScroll from "components/AppScroll";
import Users from "components/Users";
import Companies from "components/Companies";
import NewCompany from "components/NewCompany";
import NewUser from "components/NewUser";
import NewPort from "components/Port/NewPort";
import EditPort from "components/Port/EditPort";
import NewTerminal from "components/Terminal/NewTerminal";
import EditTerminal from "components/Terminal/EditTerminal";
import NewBerth from "components/Berth/NewBerth";
import EditBerth from "components/Berth/EditBerth";
import NewUserApi from "components/NewUserApi";
import Permissions from "components/Permissions";
import CompanyDetailsComponent from "components/Companies/CompanyDetailsComponent";
import ImportCompany from "components/Companies/ImportCompany";
import NewMaterial from "components/Material/NewMaterial";
import EditMaterial from "components/Material/EditMaterial";
import NewEditNodeMaterial from "components/Material/NewEditNodeMaterial";
import AuditLog from "components/AuditLog";
import SplashPage from "./SplashPage";
import styles from "./styles.module.scss";
import classnames from "classnames";
import "react-toastify/dist/ReactToastify.css";

import * as notificationSelectors from "state/notifications/selectors";
import * as notificationCreators from "state/notifications/creators";
import * as userSelectors from "state/user/selectors";
import * as userCreators from "state/user/creators";
import * as redux from "utils/redux";
import AddDashboard from "../Dashboard/AddDashboard";
import { getCurrentUser } from "../../api/user";
import * as analytics from "../../instrumentation";

class App extends React.Component {
  state = {};

  componentDidMount() {
    getCurrentUser().then(user => {
      if (user.type === "success") {
        // For some reason, any pipe character in the id is url-encoded when arriving here.
        // Sending an id containing "%" will cause various identity issues in Woopra.
        const correctedId = decodeURI(user.data.id);
        analytics.identify(correctedId, user.data.name, user.data.email);
      } else console.error("Could not fetch current user");
    });
    this.props.userCreators.req();
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  componentDidUpdate = () => {
    if (this.props.toast && this.props.toast.show) {
      this.showToast(this.props.toast.type, this.props.toast.message);
    }
  };

  showToast = (type, message) => {
    toast(message, {
      type: type,
      onClose: this.closeToast(),
      className: classnames(styles.cargoToast)
    });
  };

  closeToast = () => {
    this.props.notificationCreators.hideToast();
  };

  render() {
    if (this.state.error) {
      // Show an error page for catched errors.
      return <AppError error={this.state.error} />;
    }

    if (this.props.isLoading) {
      // Wait for the user state module to finish fetching the user profile,
      // and for it to select the company, terminal, and material filters.
      return <Loading />;
    }

    // It is important that we refresh all data when a new company is selected.
    // This root component uses a key derived from the current company,
    // so that everything gets re-mounted when the company is changed.
    return (
      <AppScroll location={this.props.location}>
        <Header match={this.props.match} />
        <Switch>
          <Route exact path="/" component={SplashPage} />
          <Route exact path="/companies" component={Companies} />
          <Route
            exact
            path="/companies/:companyId"
            component={CompanyDetailsComponent}
          />
          <Route exact path="/newcompany" component={NewCompany} />
          <Route
            exact
            path="/companies/import/:companyId"
            component={ImportCompany}
          />
          <Route path="/users/:userId" component={Users} />
          <Route path="/user/new/:companyId" component={NewUser} />
          <Route path="/user/api/new/:companyId" component={NewUserApi} />
          <Route
            path="/permissions/:companyId/:userId"
            component={Permissions}
          />
          <Route
            path="/port/new/:companyId"
            render={props => <NewPort {...props} />}
          />
          <Route
            path="/port/edit/:companyId/:companyPortId"
            render={props => <EditPort {...props} />}
          />
          <Route
            path="/terminal/new/:companyId/:companyPortId"
            render={props => <NewTerminal {...props} />}
          />
          <Route
            path="/terminal/edit/:companyId/:companyPortId/:terminalId"
            render={props => <EditTerminal {...props} />}
          />
          <Route
            path="/berth/new/:companyId/:companyPortId/:terminalId"
            render={props => <NewBerth {...props} />}
          />
          <Route
            path="/berth/edit/:companyId/:companyPortId/:terminalId/:berthId"
            render={props => <EditBerth {...props} />}
          />

          <Route path="/materials/new/:companyId" component={NewMaterial} />
          <Route
            path="/materials/edit/:companyId/:materialId"
            component={EditMaterial}
          />

          <Route
            path="/portmaterial/new/:nodeType/:companyId/:ownerId"
            component={NewEditNodeMaterial}
          />
          <Route
            path="/portmaterial/edit/:nodeType/:companyId/:ownerId/:id"
            component={NewEditNodeMaterial}
          />

          <Route
            path="/terminalmaterial/new/:nodeType/:companyId/:portId/:ownerId"
            component={NewEditNodeMaterial}
          />
          <Route
            path="/terminalmaterial/edit/:nodeType/:companyId/:portId/:ownerId/:id"
            component={NewEditNodeMaterial}
          />

          <Route
            path="/berthmaterial/new/:nodeType/:companyId/:portId/:terminalId/:ownerId"
            component={NewEditNodeMaterial}
          />
          <Route
            path="/berthmaterial/edit/:nodeType/:companyId/:portId/:terminalId/:ownerId/:id"
            component={NewEditNodeMaterial}
          />

          <Route path="/dashboard/add/:companyId" component={AddDashboard} />

          <Route path="/auditlog" component={AuditLog} />
        </Switch>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Slide}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </AppScroll>
    );
  }
}

export default redux.connect(
  state => ({
    isLoading: userSelectors.isLoading(state),
    toast: notificationSelectors.toast(state)
  }),
  { userCreators, notificationCreators }
)(App);
