import React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";

const Header = () => (
  <ul className={classnames(styles.row, styles.rowHeader)}>
    <li>Name</li>
    <li>Display name</li>
    <li>Number of users</li>
  </ul>
);

export default Header;
