import { Fetched } from "../types/fetch";
import axios from "axios";
import * as env from "../utils/env";
import { expect200 } from "./index";
import { ReadEvent } from "../instrumentation/analytics";
import { CompanyPortId } from "../types/company";
import { GlobalBerth } from "../types/globalberth";

export function getGlobalBerths(
  companyPortId: CompanyPortId
): Promise<Fetched<GlobalBerth[]>> {
  return expect200(
    axios.get(`${env.apiPath()}/cargo/berths/${companyPortId}`),
    new ReadEvent("getGlobalBerths")
  );
}
