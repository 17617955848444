import React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";
import Select from "components/Select";

const OutboundPermissions = props => {
  const options = [
    { value: "NONE", label: "None" },
    { value: "VIEW", label: "View" },
    { value: "EDIT", label: "Edit" },
    { value: "SHARE", label: "Share" }
  ];

  if (props.materials.length === 0)
    return (
      <div>
        <ul className={classnames(styles.innerRow)} />
        <li>None</li>
      </div>
    );

  return props.materials.map((row, index) => (
    <div key={index}>
      <ul className={classnames(styles.innerRow)}>
        <li className={classnames(styles.rowTitle)}>{row.name}</li>
        <li>Schedule: </li>
        <li>
          <Select
            options={options}
            className={classnames(styles.moduleSelect)}
            value={options.find(option => option.value === row.schedule)}
            onChange={opt =>
              props.setMaterialPermission({
                terminal: props.openTerminalIndex,
                material: index,
                schedule: opt.value,
                inventory: row.inventory,
                inboundOrOutbound: row.inboundOrOutbound,
                materialId: row.materialId
              })
            }
          />
        </li>
        <li>Inventory: </li>
        <li>
          <Select
            options={options}
            className={classnames(styles.moduleSelect)}
            value={options.find(option => option.value === row.inventory)}
            onChange={opt =>
              props.setMaterialPermission({
                terminal: props.openTerminalIndex,
                material: index,
                inventory: opt.value,
                schedule: row.schedule,
                inboundOrOutbound: row.inboundOrOutbound,
                materialId: row.materialId
              })
            }
          />
        </li>
      </ul>
    </div>
  ));
};

export default OutboundPermissions;
