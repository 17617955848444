import * as actions from "./actions";

export const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actions.err:
      return {
        error: action.payload.error
      };
    case actions.triggerImport:
      return {
        ...state,
        loading: true
      };
    case actions.finishImport:
      return {
        ...state,
        ...action.payload
      };
    case actions.firstReq:
      return {
        loading: true
      };
    case actions.res:
      return {
        ...action.payload,
        previousState: state.previousState || action.payload,
        loading: false
      };

    case actions.setModulePermission:
      return {
        ...state,
        permissions: [
          ...(state.permissions || []).filter(
            p => p.name !== action.payload.name
          ),
          action.payload
        ]
      };

    case actions.setTerminalPermission:
      return mapTerminal(
        state,
        action.payload.terminal
      )(t => ({
        ...t,
        permissions: [
          ...t.permissions.filter(p => p.name !== action.payload.name),
          {
            name: action.payload.name,
            permission: action.payload.permission
          }
        ]
      }));

    case actions.setMaterialPermission:
      return mapTerminal(
        state,
        action.payload.terminal
      )(t => ({
        ...t,
        materials: t.materials.map(material => {
          if (
            material.materialId === action.payload.materialId &&
            material.inboundOrOutbound === action.payload.inboundOrOutbound
          ) {
            return {
              ...material,
              inventory: action.payload.inventory,
              schedule: action.payload.schedule
            };
          }
          return material;
        })
      }));

    case actions.update:
      return state;
    case actions.importPermissions:
      return state;
    default:
      return state;
  }
};

const mapTerminal = (state, terminalIndex) => map => ({
  ...state,
  terminals: state.terminals.map((t, i) => (i === terminalIndex ? map(t) : t))
});
