import React, {
  useState,
  useEffect,
  FunctionComponent,
  ChangeEvent
} from "react";
import { useForm } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import Loading from "../Loading";
import { RouteComponentProps, withRouter } from "react-router";
import { Fetch, uninitialized } from "../../types/fetch";
import { getCompany, importCompany } from "../../api/company";
import { Company } from "../../types/company";
import { showError } from "../../utils/error";

interface Params {
  companyId: string;
}

const ImportComponent: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId } = match.params;
  const { handleSubmit } = useForm<String>();

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    getCompany(companyId).then(setCompany);
  }, [companyId]);

  const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file: File = files[0];
      setFile(file);
    }
  };

  const onSubmit = () => {
    if (file) {
      importCompany(companyId, file).then(imported => {
        if (imported.type === "success") {
          returnToCompanyPage();
        } else {
          showError(imported.msg);
        }
      });
    }
  };

  const returnToCompanyPage = () => {
    history.push(`/companies/${companyId}`);
  };

  const isDisabled: boolean = !file;

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (company.type === "loading") {
    return <Loading />;
  }
  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }
  const companyName: string = company.data.name;

  return (
    <div className={styles.content}>
      <h1>Import company information for {companyName}</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
        <label className={styles.label} htmlFor="file">
          File to import*
        </label>
        <input
          type="file"
          className={styles.input}
          accept=".xlsx"
          onChange={onFileSelected}
        />
        <Button
          title="Cancel"
          className={styles.inputButton}
          onClick={returnToCompanyPage}
        >
          Cancel
        </Button>
        <Button
          title="Create"
          type="submit"
          className={styles.inputButton}
          disabled={isDisabled}
        >
          Import
        </Button>
      </form>
    </div>
  );
};

export default withRouter(ImportComponent);
