import React, { useState } from "react";
import styles from "./styles.module.scss";
import formStyles from "./../form.module.scss";
import classnames from "classnames";
import iconPencil from "assets/icons/pencil.svg";
import { Link } from "react-router-dom";
import {
  Berth,
  CompanyId,
  CompanyPortId,
  CompanyTerminalId
} from "../../types/company";
import { Button } from "../Button";

import * as H from "history";
import ReactModal from "react-modal";
import iconTrash from "../../assets/icons/trash.svg";
import { deleteBerth } from "../../api/berth";
import { showError } from "../../utils/error";
import { GlobalBerth } from "../../types/globalberth";

interface Props {
  history: H.History;
  reload: () => void;
  berths: Berth[];
  globalBerths: GlobalBerth[];
  companyId: CompanyId;
  companyPortId: CompanyPortId;
  terminalId: CompanyTerminalId;
}
const Berths = (props: Props) => {
  const [deletableBerth, setDeletableBerth] = useState<Berth>();

  if (!props.berths) {
    return null;
  }

  const berths = props.berths.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  );

  const berthIdToName = new Map(
    props.globalBerths.map(_ => [_.berthId, _.name])
  );

  const addBerthPage = () => {
    props.history.push(
      `/berth/new/${props.companyId}/${props.companyPortId}/${props.terminalId}`
    );
  };

  return (
    <>
      <ul className={styles.innerRowHeader}>
        <li>Name</li>
        <li>Global berth</li>
        <li className={classnames(styles.actionColumn)}>Action</li>
      </ul>
      {berths.map((berth, index) => (
        <ul key={index} className={classnames(styles.innerRow)}>
          <li>{berth.name}</li>
          <li>
            {berth.berthId == null
              ? ""
              : berthIdToName.get(berth.berthId) + " (" + berth.berthId + ")"}
          </li>
          <li className={classnames(styles.actionColumn)}>
            <Link
              to={`/berth/edit/${props.companyId}/${props.companyPortId}/${props.terminalId}/${berth.id}`}
            >
              <img src={iconPencil} alt="" />
            </Link>
            <img
              src={iconTrash}
              alt=""
              onClick={() => setDeletableBerth(berth)}
            />

            {deletableBerth && (
              <ReactModal
                isOpen={true}
                contentLabel="Delete"
                className={styles.modal}
                overlayClassName={styles.modalOverlay}
              >
                <p>
                  Are you sure you want to delete berth {deletableBerth.name}?
                </p>
                <div>
                  <Button
                    className={classnames(styles.modalButton)}
                    onClick={() => setDeletableBerth(undefined)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classnames(styles.modalButton)}
                    onClick={() =>
                      deleteBerth(deletableBerth.id).then(b => {
                        if (b.type === "success") {
                          setDeletableBerth(undefined);
                          props.reload();
                        } else {
                          showError(b.msg);
                        }
                      })
                    }
                  >
                    Delete
                  </Button>
                </div>
              </ReactModal>
            )}
          </li>
        </ul>
      ))}
      <br />
      <form onSubmit={() => addBerthPage()} className={formStyles.inputForm}>
        <Button type="submit" className={formStyles.inputButton}>
          Add berth
        </Button>
      </form>
    </>
  );
};

export default Berths;
