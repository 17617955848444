import axios from "axios";
import * as redux from "utils/redux";
import * as actions from "./actions";
import * as env from "utils/env";

export const req = redux.action(actions.req, () => onReq);
export const res = redux.action(actions.res);
export const err = redux.action(actions.err);

const onReq = (action, dispatch) => {
  return axios
    .get(`${env.apiPath()}/cargo/companies`)
    .then(response => dispatch(res({ data: response.data })))
    .catch(error => dispatch(err({ error })));
};
