import woopra, { configureWoopra } from "./woopra";
import { Company } from "../types/company";

/**
 * We want to send some properties with every following event.
 */
let woopraProps: WoopraProps = {};

interface WoopraProps {
  companyId?: string;
  companyName?: string;
}

export type EventType = "read" | "write";

export interface Event {
  action: string;
  type: EventType;
}

export class ReadEvent implements Event {
  action: string;
  type: EventType;

  constructor(action: string) {
    this.action = action;
    this.type = "read";
  }
}

export class WriteEvent implements Event {
  action: string;
  type: EventType;

  constructor(action: string) {
    this.action = action;
    this.type = "write";
  }
}

export const init = () => {
  configureWoopra();
};

export const identify = (id: string, name: string, email: string) => {
  const domain = email.split("@")[1];
  const company = domain;
  woopra.identify({ "Email domain": domain, id, name, email, company });
  woopra.track("login", {
    "Email domain": domain,
    id,
    name,
    email,
    company
  });
};

export const changeCompany = (company: Company) => {
  woopraProps.companyId = company.id;
  woopraProps.companyName = company.name;
};

export const resetCompany = () => {
  woopraProps.companyId = undefined;
  woopraProps.companyName = undefined;
};

export const event = (
  event: Event,
  error?: string,
  properties?: { [k: string]: string | number | undefined }
) => {
  woopra.track(event.action, {
    ...properties,
    type: event.type,
    error: error,
    ...woopraProps
  });
};
