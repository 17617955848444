import axios from "axios";
import * as redux from "utils/redux";
import * as actions from "./actions";
import * as env from "utils/env";

export const loading = redux.action(actions.loading);
export const importFromUser = redux.action(actions.triggerImport, () => onReq);
export const firstReq = redux.action(actions.firstReq, () => onReq);
export const res = redux.action(actions.res);
export const err = redux.action(actions.err);
export const setModulePermission = redux.action(actions.setModulePermission);
export const setTerminalPermission = redux.action(
  actions.setTerminalPermission
);
export const setMaterialPermission = redux.action(
  actions.setMaterialPermission
);
export const importPermissions = redux.action(actions.importPermissions);

const onReq = (action, dispatch) => {
  return axios
    .get(
      encodeURI(
        `${env.apiPath()}/cargo/companies/${action.payload.companyId}/users/${
          action.payload.userId
        }`
      )
    )
    .then(response => dispatch(res(sortTerminals(response.data))))
    .catch(error => dispatch(err({ error })));
};

export const create = payload => {
  return dispatch => {
    dispatch(loading(true));
    return axios
      .post(`${env.apiPath()}/cargo/companies/${payload.companyId}/ports/`, {
        portId: payload.portId,
        displayName: payload.displayName
      })
      .then(() => {
        dispatch(loading(false));
      })
      .catch(error => dispatch(err({ error })));
  };
};

export const update = payload => (dispatch, getState) => {
  const encodedUserId = encodeURI(payload.userId);
  const data = {
    modules: getState().permissions.permissions,
    ports: getState().permissions.terminals
  };

  dispatch(loading(true));

  return axios
    .put(
      `${env.apiPath()}/cargo/companies/${
        payload.companyId
      }/users/${encodedUserId}`,
      data
    )
    .catch(error => {
      dispatch(err({ error }));
    })
    .finally(dispatch(loading(false)));
};

function sortTerminals(data) {
  return {
    ...data,
    terminals: data.terminals
      .slice(0)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
  };
}
