import {
  BerthMaterial,
  Material,
  PortMaterial,
  TerminalMaterial
} from "./material";
import { PortId } from "./globalport";
import { nonEmpty } from "../utils/stringutil";
import Dashboard from "./dashboard";
import { TerminalId } from "./globalterminal";
import { BerthId } from "./globalberth";

export type CompanyId = string;

export interface Company {
  name: string;
  id: CompanyId;
  ports: Port[];
  materials: Material[];
  dashboards: Dashboard[];
}

export type CompanyPortId = string;
export type CompanyTerminalId = string;
export type CompanyBerthId = string;

export interface Port {
  id: CompanyPortId;
  portId: PortId;
  name: string;
  displayName?: string;
  materials: PortMaterial[];
  terminals: Terminal[];
}

export function portName(port: Port): string {
  let name = port.displayName;
  return nonEmpty(name) && name !== port.name
    ? `${name} (${port.name})`
    : port.name;
}

export interface Terminal {
  id: CompanyTerminalId;
  terminalId?: TerminalId;
  name: string;
  materials: TerminalMaterial[];
  berths: Berth[];
}

export interface Berth {
  id: CompanyBerthId;
  berthId?: BerthId;
  name: string;
  materials: BerthMaterial[];
}

export interface CompanyInput {
  name: string;
  displayName: string;
}

export interface PortInput {
  companyId: CompanyId;
  portId: PortId;
  displayName?: string;
}

export interface TerminalInput {
  companyPortId: CompanyPortId;
  name: string;
}

export interface BerthInput {
  terminalId: CompanyTerminalId;
  berthId?: BerthId;
  name: string;
}

export interface CompanyImportResponse {
  id: CompanyId;
  users: UserImportResponse[];
}

export interface UserImportResponse {
  id: String;
  message: String;
}
