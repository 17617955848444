import {
  BerthMaterial,
  BerthMaterialId,
  BerthMaterialInput,
  Material,
  MaterialId,
  MaterialInput,
  PortMaterial,
  PortMaterialId,
  PortMaterialInput,
  TerminalMaterial,
  TerminalMaterialId,
  TerminalMaterialInput
} from "../types/material";
import axios from "axios";
import * as env from "../utils/env";
import { expect200 } from "./index";
import { Fetched } from "../types/fetch";
import { ReadEvent, WriteEvent } from "../instrumentation/analytics";

export function getMaterial(
  materialId: MaterialId
): Promise<Fetched<Material>> {
  return expect200(
    axios.get<Material>(`${env.apiPath()}/company/materials/${materialId}`),
    new ReadEvent("getMaterial")
  );
}

export const createMaterial = (input: MaterialInput) => {
  return expect200(
    axios.post(`${env.apiPath()}/company/materials`, input),
    new WriteEvent("createMaterial")
  );
};

export const updateMaterial = (id: MaterialId, input: MaterialInput) => {
  return expect200(
    axios.put(`${env.apiPath()}/company/materials/${id}`, input),
    new WriteEvent("updateMaterial")
  );
};

export function getPortMaterial(
  id: PortMaterialId
): Promise<Fetched<PortMaterial>> {
  return expect200(
    axios.get(`${env.apiPath()}/company/portmaterials/${id}`),
    new ReadEvent("getPortMaterial")
  );
}

export function createPortMaterial(
  input: PortMaterialInput
): Promise<Fetched<void>> {
  return expect200(
    axios.post(`${env.apiPath()}/company/portmaterials`, input),
    new WriteEvent("createPortMaterial")
  );
}

export function updatePortMaterial(
  id: PortMaterialId,
  input: PortMaterialInput
): Promise<Fetched<void>> {
  return expect200(
    axios.put(`${env.apiPath()}/company/portmaterials/${id}`, input),
    new WriteEvent("updatePortMaterial")
  );
}

export const deletePortMaterial = (
  id: PortMaterialId
): Promise<Fetched<void>> => {
  return expect200(
    axios.delete(`${env.apiPath()}/company/portmaterials/${id}`),
    new WriteEvent("deletePortMaterial")
  );
};

export function getTerminalMaterial(
  id: TerminalMaterialId
): Promise<Fetched<TerminalMaterial>> {
  return expect200(
    axios.get(`${env.apiPath()}/company/terminalmaterials/${id}`),
    new ReadEvent("getTerminalMaterial")
  );
}

export function createTerminalMaterial(
  input: TerminalMaterialInput
): Promise<Fetched<void>> {
  return expect200(
    axios.post(`${env.apiPath()}/company/terminalmaterials`, input),
    new WriteEvent("createTerminalMaterial")
  );
}

export function updateTerminalMaterial(
  id: TerminalMaterialId,
  input: TerminalMaterialInput
): Promise<Fetched<void>> {
  return expect200(
    axios.put(`${env.apiPath()}/company/terminalmaterials/${id}`, input),
    new WriteEvent("updateTerminalMaterial")
  );
}

export const deleteTerminalMaterial = (
  id: TerminalMaterialId
): Promise<Fetched<void>> => {
  return expect200(
    axios.delete(`${env.apiPath()}/company/terminalmaterials/${id}`),
    new WriteEvent("deleteTerminalMaterial")
  );
};

export function getBerthMaterial(
  id: BerthMaterialId
): Promise<Fetched<BerthMaterial>> {
  return expect200(
    axios.get(`${env.apiPath()}/company/berthmaterials/${id}`),
    new ReadEvent("getBerthMaterial")
  );
}

export function createBerthMaterial(
  input: BerthMaterialInput
): Promise<Fetched<void>> {
  return expect200(
    axios.post(`${env.apiPath()}/company/berthmaterials`, input),
    new WriteEvent("createBerthMaterial")
  );
}

export function updateBerthMaterial(
  id: BerthMaterialId,
  input: BerthMaterialInput
): Promise<Fetched<void>> {
  return expect200(
    axios.put(`${env.apiPath()}/company/berthmaterials/${id}`, input),
    new WriteEvent("updateBerthMaterial")
  );
}

export const deleteBerthMaterial = (
  id: BerthMaterialId
): Promise<Fetched<void>> => {
  return expect200(
    axios.delete(`${env.apiPath()}/company/berthmaterials/${id}`),
    new WriteEvent("deleteBerthMaterial")
  );
};
