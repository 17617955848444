import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect as reduxConnect } from "react-redux";

// Helper for redux that makes new action creators. Takes an optional action
// creator factory that can be used to dispatch subsequent actions.
export const action = (type, next) => payload => (dispatch, getState) => {
  const req = { type, payload };
  const res = dispatch(req);

  if (next) {
    return next()(req, dispatch, getState);
  }

  return res;
};

// Connector for state selectors and action creators.
export const connect = (selectors, creators) => {
  return !selectors || typeof selectors === "function"
    ? reduxConnect(selectors, mapDispatch(creators))
    : reduxConnect(mapState(selectors), mapDispatch(creators));
};

// Bind selector objects to state.
const mapState = selectors => state => {
  return _.mapValues(selectors || {}, s => bindStateSelectors(s, state));
};

// Bind creator objects to dispatch.
const mapDispatch = creators => dispatch => {
  return _.mapValues(creators || {}, c => bindActionCreators(c, dispatch));
};

// Bind selector functions to state.
const bindStateSelectors = (selectors, state) => {
  return _.mapValues(selectors, selector => selector.bind(null, state));
};
