import * as actions from "./actions";

export const reducer = (state = { state: "uninitialized" }, action) => {
  switch (action.type) {
    case actions.loadCommodities:
      return {
        commodities: action.payload,
        state: "idle"
      };
    case action.createMaterial:
      return {
        state: "creating"
      };
    case action.newMaterial:
      return {
        state: "idle"
      };
    default:
      return state;
  }
};
