import { combineReducers } from "redux";
import * as actions from "./actions";
import * as userReducer from "state/user/reducer";
import * as usersReducer from "state/users/reducer";
import * as companiesReducer from "state/companies/reducer";
import * as companyReducer from "state/company/reducer";
import * as materialpermissionsReducer from "state/materialpermissions/reducer";
import * as materialsReducer from "state/materials/reducer";
import * as portsReducer from "state/ports/reducer";
import * as permissionsReducer from "state/permissions/reducer";
import * as notificationsReducer from "state/notifications/reducer";

const combinedReducers = combineReducers({
  user: userReducer.reducer,
  users: usersReducer.reducer,
  companies: companiesReducer.reducer,
  company: companyReducer.reducer,
  ports: portsReducer.reducer,
  materialpermissions: materialpermissionsReducer.reducer,
  permissions: permissionsReducer.reducer,
  notifications: notificationsReducer.reducer,
  materials: materialsReducer.reducer
});

export const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actions.reset:
      return combinedReducers(undefined, action);
    default:
      return combinedReducers(state, action);
  }
};
