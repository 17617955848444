import React from "react";
import styles from "../styles.module.scss";
import ChangeArrow from "./ChangeArrow";

const ChangeCells = ({ change }) =>
  change ? (
    <>
      <li className={styles.rowLabel}>{change.from}</li>
      <ChangeArrow change={change} />
      <li className={styles.rowLabel}>{change.to}</li>
    </>
  ) : (
    <>
      <li className={styles.rowLabel} />
      <li className={styles.rowLabelArrow} />
      <li className={styles.rowLabel} />
    </>
  );

const ChangeList = ({ changes, title, fields }) => {
  if (!changes.length) {
    return null;
  }

  return (
    <div className={styles.permissionSet}>
      <span className={styles.terminalDirection}>{title}</span>
      <ul className={styles.innerRowHeader}>
        <li className={styles.headerTitle}>Name</li>
        {fields.map(f => (
          <li className={styles.rowLabel} key={f}>
            {f}
          </li>
        ))}
      </ul>
      {changes.map(change => (
        <ul className={styles.innerRow} key={change.name}>
          <li className={styles.rowTitle}>{change.name}</li>
          {fields.map(f => (
            <ChangeCells change={change[f]} key={f} />
          ))}
        </ul>
      ))}
    </div>
  );
};

export default ChangeList;
