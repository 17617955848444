import React from "react";
import ChangeList from "./ChangeList";

const MaterialChanges = ({ changes = [], direction }) => {
  const filteredChanges = changes.filter(
    change => change.inboundOrOutbound === direction
  );

  return (
    <ChangeList
      changes={filteredChanges}
      title={direction}
      fields={["inventory", "schedule"]}
    />
  );
};

export default MaterialChanges;
