export const isLoading = state => {
  return Boolean(state.companies.loading);
};

export const isError = state => {
  return Boolean(state.companies.error);
};

export const companies = state => {
  return state.companies;
};
