import React from "react";
import { Button, ButtonGroup } from "components/Button";
import styles from "./styles.module.scss";

class AppError extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div role="alert" className={styles.el}>
        <p className={styles.info}>
          Something went wrong. Please refresh the page and try again.
        </p>
        <ButtonGroup className={styles.buttons}>
          <Button onClick={() => window.location.reload(true)}>
            Refresh the page and try again
          </Button>
        </ButtonGroup>
        {this.props.error && (
          <p className={styles.error}>
            {this.props.error.stack || this.props.error.toString()}
          </p>
        )}
      </div>
    );
  }
}

export default AppError;
