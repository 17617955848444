import { Fetched } from "../types/fetch";
import axios from "axios";
import * as env from "../utils/env";
import { expect200, expect204 } from "./index";
import { AdminUser, User, UserExtended, UserId } from "../types/user";
import * as analytics from "../instrumentation";
import { ReadEvent, WriteEvent } from "../instrumentation/analytics";

/**
 * Return the current user of the admin application.
 */
export function getCurrentUser(): Promise<Fetched<AdminUser>> {
  return expect200(axios.get<AdminUser>(`${env.apiPath()}/cargo/user`));
}

export function getUser(userId: UserId): Promise<Fetched<UserExtended>> {
  return expect200(
    axios.get<UserExtended>(
      encodeURI(`${env.apiPath()}/cargo/users/${userId}`)
    ),
    new ReadEvent("getUser")
  );
}

export function getUsers(): Promise<Fetched<User[]>> {
  analytics.resetCompany();
  return expect200(
    axios.get(`${env.apiPath()}/cargo/users`),
    new ReadEvent("getUsers")
  );
}

export interface UserUpdate {
  fullname: string;
  email: string;
  admin: boolean;
}

export function patchUser(
  userId: UserId,
  update: Partial<UserUpdate>
): Promise<Fetched<void>> {
  return expect204(
    axios.patch(encodeURI(`${env.apiPath()}/cargo/users/${userId}`), update),
    new WriteEvent("updateUser")
  );
}
