import React, { useState, useEffect, FunctionComponent } from "react";
import { useForm, Controller } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import Select from "../Select";
import Loading from "../Loading";
import { Commodity, Material } from "types/material";
import { OptionsType } from "react-select";
import { RouteComponentProps, withRouter } from "react-router";
import { createMaterial } from "../../api/material";
import { fetchCommodities } from "../../api/commodities";
import { Fetch, uninitialized } from "../../types/fetch";
import { getCompany } from "../../api/company";
import { Company } from "../../types/company";
import { showError } from "../../utils/error";

interface Params {
  companyId: string;
}

const NewMaterial: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId } = match.params;
  const { register, handleSubmit, control, watch } = useForm<Material>();

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [commodities, setCommodities] = useState<Fetch<Commodity[]>>(
    uninitialized
  );

  useEffect(() => {
    getCompany(companyId).then(setCompany);
    fetchCommodities().then(setCommodities);
  }, [companyId]);

  const onSubmit = (state: Material) => {
    createMaterial({
      companyId: companyId,
      commodityId: state.commodity.id,
      name: state.name
    }).then(material => {
      if (material.type === "success") {
        returnToCompanyPage();
      } else {
        showError(material.msg);
      }
    });
  };

  const returnToCompanyPage = () => {
    history.push(`/companies/${companyId}`);
  };

  const name = watch("name");
  const commodityId = watch("commodity.id");

  const isDisabled: boolean =
    !name || name.replace(" ", "").length === 0 || !commodityId;

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }

  if (company.type === "loading") {
    return <Loading />;
  }

  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }

  if (commodities.type === "uninitialized") {
    return <div>Initializing</div>;
  }

  if (commodities.type === "loading") {
    return <Loading />;
  }

  if (commodities.type === "failure") {
    return <div>Ouch {commodities.msg}</div>;
  }

  const companyName: string = company.data.name;

  type OptionType = {
    value: string;
    label: string;
  };

  const commodityValues: OptionsType<OptionType> = commodities.data.map(c => ({
    value: c.id,
    label: c.name
  }));

  return (
    <div className={styles.content}>
      <h1>New material for {companyName}</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
        <label className={styles.label} htmlFor="name">
          Name*
        </label>
        <Controller
          as={<input type="text" className={styles.input} />}
          label="Name*"
          name="name"
          defaultValue={""}
          control={control}
        />
        <label className={styles.label} htmlFor="commodityId">
          Commodity*
        </label>

        <Controller
          render={({ onChange, onBlur, value, name, ref }) => (
            <Select
              className={styles.select}
              ref={register}
              value={commodityValues.filter(c => c.value === value)}
              name={name}
              onChange={(opt: OptionType) => {
                onChange(opt.value);
              }}
              options={commodityValues}
              inputRef={ref}
            />
          )}
          defaultValue={""}
          key="value"
          label="Commodity*"
          name="commodity.id"
          control={control}
          ref={register}
        />

        <Button
          title="Cancel"
          className={styles.inputButton}
          onClick={returnToCompanyPage}
        >
          Cancel
        </Button>
        <Button
          title="Create"
          type="submit"
          className={styles.inputButton}
          disabled={isDisabled}
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default withRouter(NewMaterial);
