import { RouteComponentProps, withRouter } from "react-router";
import classnames from "classnames";
import styles from "./styles.module.scss";
import React, { useState, useEffect, FunctionComponent, useRef } from "react";
import { Fetch, uninitialized, loading } from "../../types/fetch";
import { search } from "../../api/auditlog";
import AppError from "../AppError";
import { Tooltip } from "../Tooltip";

interface Params {}

const AuditLog: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const isMounted = useRef(1);
  const [entries, setEntries] = useState<Fetch<AuditLogEntry[]>>(uninitialized);

  const [path, setPath] = useState<string>();
  const [method, setMethod] = useState<string>();
  const [from, setFrom] = useState<string>();
  const [to, setTo] = useState<string>();
  const [user, setUser] = useState<string>();
  const [params, setParams] = useState<string>();
  const [content, setContent] = useState<string>();
  const [update, setUpdate] = useState<string>();

  useEffect(() => {
    isMounted.current = 1;
    setEntries(loading);
    search(path, method, from, to, user, params, content).then(newEntries => {
      if (isMounted.current) {
        console.log(newEntries);
        setEntries(newEntries);
      }
    });
    return () => {
      isMounted.current = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  function fetch(e: React.FocusEvent<HTMLInputElement>) {
    setUpdate(e.target.value);
  }

  if (entries.type === "uninitialized")
    return <main className={styles.content}>Uninitialized</main>;

  if (entries.type === "failure") return <AppError>{entries.msg}</AppError>;

  if (entries.type === "loading") return <main>Loading Audit Log...</main>;

  function ParamsComponent(params: Object): JSX.Element {
    return (
      <div className="params">
        {Array.from(Object.entries(params)).map(([name, values], index) => (
          <div key={index}>
            <span className="paramName">{name}</span> =
            {(values as string[]).map((value, index) => (
              <span key={index}>
                {" "}
                {value} {(values as string[]).length === 0 && ", "}
              </span>
            ))}
          </div>
        ))}
      </div>
    );
  }

  function asParamsString(params: Object): string {
    return Array.from(Object.entries(params))
      .flatMap(([name, values], index) =>
        (values as string[]).map(value => `${name}=${value}`)
      )
      .join("&");
  }

  return (
    <>
      <div className={classnames(styles.subHeader)}>
        <ul className={classnames(styles.row, styles.rowHeader)}>
          <li>
            <Tooltip>Path</Tooltip>
          </li>
          <li>
            <Tooltip>Method</Tooltip>
          </li>
          <li>
            <Tooltip>Time</Tooltip>
          </li>
          <li>
            <Tooltip>User</Tooltip>
          </li>
          <li>
            <Tooltip>Params</Tooltip>
          </li>
          <li>
            <Tooltip>Content</Tooltip>
          </li>
        </ul>
        <ul className={classnames(styles.row)}>
          <li>
            <input
              className={classnames(styles.input)}
              value={path}
              onBlur={fetch}
              onChange={e => setPath(e.target.value)}
              placeholder="Path"
            />
          </li>
          <li>
            <input
              className={classnames(styles.input)}
              value={method}
              onBlur={fetch}
              onChange={e => setMethod(e.target.value)}
              placeholder="Method"
            />
          </li>
          <li>
            <input
              className={classnames(styles.input)}
              value={from}
              onBlur={fetch}
              onChange={e => setFrom(e.target.value)}
              placeholder="From"
            />
          </li>
          <li>
            <input
              className={classnames(styles.input)}
              value={user}
              onBlur={fetch}
              onChange={e => setUser(e.target.value)}
              placeholder="User"
            />
          </li>
          <li>
            <input
              className={classnames(styles.input)}
              value={params}
              onBlur={fetch}
              onChange={e => setParams(e.target.value)}
              placeholder="Params"
            />
          </li>
          <li>
            <input
              className={classnames(styles.input)}
              value={content}
              onBlur={fetch}
              onChange={e => setContent(e.target.value)}
              placeholder="Content"
            />
          </li>
        </ul>
        <ul className={classnames(styles.row)}>
          <li> </li>
          <li> </li>
          <li>
            <input
              className={classnames(styles.input)}
              value={to}
              onBlur={fetch}
              onChange={e => setTo(e.target.value)}
              placeholder="To"
            />
          </li>
          <li> </li>
          <li> </li>
        </ul>
      </div>
      <div className={classnames(styles.scrollableRows)}>
        {entries.data.map(entry => (
          <ul className={classnames(styles.row)} key={entry.id}>
            <li
              onClick={() => {
                setPath(entry.path);
                setUpdate(entry.path);
              }}
            >
              {entry.path}
            </li>
            <li
              onClick={() => {
                setMethod(entry.method);
                setUpdate(entry.method);
              }}
            >
              {entry.method}
            </li>
            <li
              onClick={() => {
                setFrom(entry.time);
                setTo(entry.time);
                setUpdate(entry.time);
              }}
            >
              {entry.time}
            </li>
            <li
              onClick={() => {
                setUser(entry.user);
                setUpdate(entry.user);
              }}
            >
              {entry.user}
            </li>
            <li
              onClick={() => {
                setParams(asParamsString(entry.params));
                setUpdate(asParamsString(entry.params));
              }}
            >
              {ParamsComponent(entry.params)}
            </li>
            <li
              onClick={() => {
                setUpdate(entry.content);
              }}
            >
              {entry.content}
            </li>
          </ul>
        ))}
      </div>
    </>
  );
};

export default withRouter(AuditLog);
