import React from "react";
import ReactModal from "react-modal";
import classnames from "classnames";
import _ from "lodash";
import { Button } from "components/Button";
import styles from "../styles.module.scss";
import MaterialChanges from "./MaterialChanges";
import ModuleChanges from "./ModuleChanges";

const ConfirmPermissionsModal = props => {
  const materialChanges = _.groupBy(props.changes.materialChanges, "terminal");

  const terminalChanges = _.groupBy(props.changes.terminalChanges, "terminal");

  const terminals = [materialChanges, terminalChanges]
    .map(Object.keys)
    .reduce(toUniqueList);

  return (
    <ReactModal
      isOpen={props.showConfirmPermissionsModal}
      contentLabel="Update Permission"
      className={classnames(styles.modal, styles.editModal)}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modalHeader}>
        Are you sure you want to make these changes for {props.user}?
      </div>
      <div className={styles.modalContent}>
        <ModuleChanges changes={props.changes.moduleChanges} />
        {terminals.map(terminal => (
          <React.Fragment key={terminal}>
            <span className={styles.terminalTitle}>{terminal}</span>
            <ModuleChanges changes={terminalChanges[terminal]} />
            <MaterialChanges
              changes={materialChanges[terminal]}
              direction={"inbound"}
            />
            <MaterialChanges
              changes={materialChanges[terminal]}
              direction={"outbound"}
            />
          </React.Fragment>
        ))}
      </div>
      <div className={styles.confirmButtons}>
        <Button
          title="No"
          className={styles.modalButton}
          onClick={props.handleCloseModal}
          isSecondary
        >
          No
        </Button>
        <Button
          title="Yes"
          className={styles.modalButton}
          onClick={props.handleUpdatePermissions}
        >
          Yes
        </Button>
      </div>
    </ReactModal>
  );
};

export default ConfirmPermissionsModal;

const toUniqueList = (a, b) => [...a, ...b.filter(v => !a.includes(v))];
