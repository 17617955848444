export const err = "company/err";
export const req = "company/req";
export const res = "company/res";
export const update = "company/update";
export const loading = "company/loading";
export const deleteTerminal = "company/delete";
export const deleteUser = "company/delete";
export const addMaterialReq = "company/addMaterialReq";
export const addMaterialRes = "company/addMaterialRes";
export const addCompanyRes = "company/addCompanyRes";
export const addCompanyReq = "company/addCompanyReq";
export const deleteMaterialReq = "company/deleteMaterialReq";
export const deleteMaterialRes = "company/deleteMaterialRes";
export const updateMaterialReq = "company/updateMaterialReq";
export const updateMaterialRes = "company/updateMaterialRes";
