import React, { useEffect, useState } from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import iconPencil from "assets/icons/pencil.svg";
import iconTrash from "assets/icons/trash.svg";
import { CompanyUser } from "../../types/user";
import { CompanyId } from "../../types/company";
import { UserPermissions } from "../Users/UserPermissions";

interface Props {
  user: CompanyUser;
  defaultOpen: boolean;
  companyId: CompanyId;
  deleteUser: (user: CompanyUser) => void;
}

export const UserRow = (props: Props) => {
  const { user, defaultOpen } = props;

  const [open, setOpen] = useState<boolean>(defaultOpen);

  useEffect(() => {
    setOpen(props.defaultOpen);
  }, [props.defaultOpen]);

  return (
    <div>
      <ul
        className={
          open
            ? classnames(
                styles.rowOpen,
                styles.dropdownContentContainer,
                styles.innerRow
              )
            : classnames(styles.dropdownContentContainer, styles.innerRow)
        }
        onClick={() => setOpen(!open)}
      >
        <li>{user.email}</li>
        <li>{user.fullname}</li>
        <li className={classnames(styles.actionColumn)}>
          <Link to={"/permissions/" + props.companyId + "/" + user.userid}>
            <img src={iconPencil} alt="" />
          </Link>
          <img
            src={iconTrash}
            alt=""
            onClick={(event: React.MouseEvent<HTMLImageElement>) => {
              event.preventDefault();
              props.deleteUser(user);
            }}
          />
        </li>
      </ul>
      {open && (
        <div className={classnames(styles.contentInner)}>
          <UserPermissions company={user} />
        </div>
      )}
    </div>
  );
};
