import { Fetched } from "../types/fetch";
import axios from "axios";
import * as env from "../utils/env";
import { expect200 } from "./index";
import { CompanyPortId, Port, PortInput } from "../types/company";
import { ReadEvent, WriteEvent } from "../instrumentation/analytics";

export function getPort(id: CompanyPortId): Promise<Fetched<Port>> {
  return expect200(
    axios.get<Port>(`${env.apiPath()}/company/ports/${id}`),
    new ReadEvent("getPort")
  );
}

export const createPort = (input: PortInput): Promise<Fetched<Port>> => {
  return expect200(
    axios.post(`${env.apiPath()}/company/ports`, input),
    new WriteEvent("createPort")
  );
};

export const updatePort = (
  id: CompanyPortId,
  input: PortInput
): Promise<Fetched<Port>> => {
  return expect200(
    axios.put(`${env.apiPath()}/company/ports/${id}`, input),
    new WriteEvent("updatePort")
  );
};

export const deletePort = (id: CompanyPortId): Promise<Fetched<void>> => {
  return expect200(
    axios.delete(`${env.apiPath()}/company/ports/${id}`),
    new WriteEvent("deletePort")
  );
};
