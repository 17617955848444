import axios from "axios";
import * as env from "utils/env";
import { logoutUrl } from "./env";

export const useLogoutInterceptor = () => {
  axios.interceptors.response.use(null, error => {
    if (error.response && error.response.status === 401) {
      window.location.replace(env.loginUrl());
    } else {
      return Promise.reject(error);
    }
  });
};

export const useErrorInterceptor = () => {
  axios.interceptors.response.use(res => {
    if (!res) {
      throw new Error("unknown error");
    }

    if (res.status >= 200 && res.status < 300) {
      return res;
    }

    const error = new Error(res.statusText);
    error.response = res;
    throw error;
  });
};

export const useTokenInterceptor = () => {
  axios.interceptors.request.use(config => {
    config.headers["x-xsrf-token"] = tokenCookieValue();
    return config;
  });
};

export const tokenCookieValue = () => {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)xsrf-token\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
};

export const logout = () => {
  window.location.replace(logoutUrl());
};
