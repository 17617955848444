import React from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";
import styles from "./styles.module.scss";

export class Tooltip extends React.Component {
  state = {};

  onMouseMove = event => {
    const x = parseInt(event.clientX, 10);
    const y = parseInt(event.clientY, 10);

    // Flip the tooltip position if it is right of center.
    const isRight = x > window.innerWidth * 0.5;

    this.setState({
      isOpen: true,
      isRight,
      x,
      y
    });
  };

  onMouseOut = () => {
    this.setState({
      isOpen: false
    });
  };

  targetTag() {
    // Allow passing a custom tag in order to use tooltips inside svg elements.
    return this.props.tag || "span";
  }

  targetProps() {
    return {
      className: this.props.className,
      onMouseMove: this.onMouseMove,
      onMouseOut: this.onMouseOut
    };
  }

  tooltipStyle() {
    return {
      top: this.state.y,
      left: this.state.x
    };
  }

  tooltipClass() {
    return classnames(
      styles.text,
      this.state.isRight && styles.textRight,
      this.props.showAnimation !== false && styles.textAnimated
    );
  }

  render() {
    if (!this.props.children) {
      return null;
    }

    return (
      <React.Fragment>
        {React.createElement(
          this.targetTag(),
          this.targetProps(),
          this.props.children
        )}
        {this.props.text && this.state.isOpen && (
          <TooltipPortal>
            <span className={this.tooltipClass()} style={this.tooltipStyle()}>
              {this.props.text}
            </span>
          </TooltipPortal>
        )}
      </React.Fragment>
    );
  }
}

export const TooltipPortal = props =>
  ReactDOM.createPortal(props.children, TooltipPortalNode);

export const TooltipPortalNode = document.getElementById("tooltip");
