import React from "react";
import classnames from "classnames";
import InboundPermissions from "./InboundPermissions";
import OutboundPermissions from "./OutboundPermissions";
import styles from "./styles.module.scss";

const PermissionList = props => {
  if (!props.permissions) return null;

  return props.permissions.map((row, index) => (
    <div
      className={props.openTerminal === row.portId ? styles.rowOpen : null}
      key={index}
    >
      <ul
        className={styles.row}
        onClick={() => props.toggleTerminal(row.portId)}
      >
        <li>{row.name}</li>
      </ul>
      <i
        className={styles.lblToggle}
        onClick={() => props.toggleTerminal(row.portId)}
      />
      <div
        className={classnames(
          styles.collapsibleContent,
          props.openTerminal === row.portId && styles.open
        )}
      >
        {props.openTerminal === row.portId && (
          <>
            <div className={styles.moduleContent}>
              <div className={styles.materialColumn}>
                <div className={styles.materialHeader}>Inbound</div>
                <InboundPermissions
                  materials={row.materials
                    .filter(
                      material => material.inboundOrOutbound === "inbound"
                    )
                    .sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    )}
                  setMaterialPermission={props.setMaterialPermission}
                  openTerminalIndex={index}
                />
              </div>
              <div className={styles.materialColumn}>
                <div className={styles.materialHeader}>Outbound</div>
                <OutboundPermissions
                  materials={row.materials
                    .filter(
                      material => material.inboundOrOutbound === "outbound"
                    )
                    .sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    )}
                  setMaterialPermission={props.setMaterialPermission}
                  openTerminalIndex={index}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ));
};

export default PermissionList;
