import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PortMaterialsComponent from "./PortMaterialsComponent";
import iconPencil from "assets/icons/pencil.svg";
import iconTrash from "assets/icons/trash.svg";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { Port, portName } from "../../types/company";
import ReactModal from "react-modal";
import Section from "../Page/Section";
import { CompanyId } from "../../types/company";
import { Button } from "../Button";
import {
  CompanyUser,
  MaterialPermission,
  Permission,
  TerminalPermission
} from "../../types/user";
import PermissionComponent from "./PermissionsComponent";
import formStyles from "../form.module.scss";
import * as H from "history";

interface PortProps {
  port: Port;
  companyId: CompanyId;
  users: { user: CompanyUser; permissions: TerminalPermission }[];
  defaultOpen: boolean;
  deletePort: () => void;
}

const PortComponent = (props: PortProps) => {
  const { port, users } = props;
  const [open, setOpen] = useState<boolean>(props.defaultOpen);

  useEffect(() => {
    setOpen(props.defaultOpen);
  }, [props.defaultOpen]);

  type UserTerminalPermissionTypes = Record<
    string,
    {
      user: CompanyUser;
      permission: Permission;
    }[]
  >;

  const userTerminalPermissionType: UserTerminalPermissionTypes = users
    .flatMap(({ user, permissions }) =>
      permissions.permissions.map(permission => ({
        permissionName: permission.name,
        permission: permission.permission,
        user: user
      }))
    )
    .reduce((prev, current) => {
      prev[current.permissionName] = [
        ...(prev[current.permissionName] || []),
        current
      ];
      return prev;
    }, {} as UserTerminalPermissionTypes);

  const userMaterialPermissions: {
    permission: MaterialPermission;
    user: CompanyUser;
  }[] = users.flatMap(({ user, permissions }) =>
    permissions.materials.map(permission => ({
      permission: permission,
      user: user
    }))
  );

  const permissionNames: string[] = Reflect.ownKeys(userTerminalPermissionType)
    .map(i => i.toString())
    .sort((a, b) => (a.toLocaleString() > b.toLocaleString() ? 1 : -1));

  const materialNames: string[] = Array.from(
    new Set(port.materials.map(m => m.material.name))
  ).sort();

  return (
    <div
      className={
        open
          ? classnames(styles.rowOpen, styles.dropdownContentContainer)
          : classnames(styles.dropdownContentContainer)
      }
    >
      <ul
        className={
          open
            ? classnames(styles.innerRow, styles.open)
            : classnames(styles.innerRow)
        }
        onClick={() => setOpen(!open)}
      >
        <li>{portName(port)}</li>
        <li>{port.portId}</li>
        <li>{materialNames.join(", ")}</li>
        <li>
          {port.terminals
            .map(t => t.name)
            .sort()
            .join(", ")}
        </li>
        <li>
          {port.terminals
            .flatMap(t => t.berths)
            .map(b => b.name)
            .sort()
            .join(", ")}
        </li>
        <li className={classnames(styles.actionColumn)}>
          <Link to={`/port/edit/${props.companyId}/${port.id}`}>
            <img src={iconPencil} alt="" />
          </Link>
          <img src={iconTrash} alt="" onClick={() => props.deletePort()} />
        </li>
      </ul>
      {open && (
        <>
          <PortMaterialsComponent
            portMaterials={port.materials}
            materialPermissions={userMaterialPermissions}
          />
          <div className={classnames(styles.terminalPermission)}>
            {permissionNames.length > 0 && <header>Port permissions</header>}
            {permissionNames.map((name: string) => (
              <div className={classnames(styles.permissionGroup)}>
                <div>{name}</div>
                <PermissionComponent
                  userPermissions={userTerminalPermissionType[name] || []}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

type UserTerminalPermission = Record<
  string,
  {
    user: CompanyUser;
    permissions: TerminalPermission;
  }[]
>;

interface TerminalsProps {
  history: H.History;
  companyId: CompanyId;
  ports?: Port[];
  users: CompanyUser[];
  deletePort: (port: Port) => Promise<void>;
}

const Ports = (props: TerminalsProps) => {
  const { companyId, users } = props;

  const [deletablePort, setDeletablePort] = useState<Port>();
  const [toggle, setToggle] = useState<boolean>(false);

  const sortedPorts =
    props.ports?.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    ) || [];

  const terminalUsers: UserTerminalPermission = users
    .flatMap(user =>
      user.terminals.map(terminal => ({
        terminal: terminal.id,
        user: user,
        permissions: terminal
      }))
    )
    .reduce((prev, current) => {
      prev[current.terminal] = [...(prev[current.terminal] || []), current];
      return prev;
    }, {} as UserTerminalPermission);

  const addPortPage = () => {
    props.history.push(`/port/new/${companyId}`);
  };

  return (
    <Section title={"Ports"} onClick={() => setToggle(!toggle)}>
      <ul className={classnames(styles.innerRowHeader)}>
        <li>Name</li>
        <li>UN/LOCODE</li>
        <li>Materials</li>
        <li>Terminals</li>
        <li>Berths</li>
        <li className={classnames(styles.actionColumn)}>Action</li>
      </ul>
      {sortedPorts.map((port, index) => (
        <PortComponent
          companyId={companyId}
          port={port}
          key={index}
          defaultOpen={toggle}
          users={terminalUsers[port.portId] || []}
          deletePort={() => setDeletablePort(port)}
        />
      ))}
      <br />
      <Button
        type="submit"
        className={formStyles.button}
        onClick={() => addPortPage()}
      >
        Add port
      </Button>
      {deletablePort && (
        <ReactModal
          isOpen={true}
          contentLabel="Delete"
          className={styles.modal}
          overlayClassName={styles.modalOverlay}
        >
          <p>Are you sure you want to delete port {deletablePort.name}?</p>
          <div>
            <Button
              title="Cancel"
              className={classnames(styles.modalButton)}
              onClick={() => setDeletablePort(undefined)}
            >
              Cancel
            </Button>
            <Button
              title="Delete"
              className={classnames(styles.modalButton)}
              onClick={() =>
                props
                  .deletePort(deletablePort)
                  .then(() => setDeletablePort(undefined))
              }
            >
              Delete
            </Button>
          </div>
        </ReactModal>
      )}
    </Section>
  );
};

export default Ports;
