import * as actions from "./actions";

export const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actions.showToast:
      return {
        ...state,
        toast: {
          show: true,
          type: action.payload.toastType,
          message: action.payload.toastMessage
        }
      };
    case actions.hideToast:
      return {
        toast: {
          show: false
        }
      };
    default:
      return state;
  }
};
