import axios from "axios";
import * as redux from "utils/redux";
import * as actions from "./actions";
import * as env from "utils/env";
import * as companiesCreators from "state/companies/creators";
import * as notificationCreators from "state/notifications/creators";

export const req = redux.action(actions.req, () => onReq);
export const res = redux.action(actions.res);
export const err = redux.action(actions.err);
export const loading = redux.action(actions.loading);

export const addMaterialReq = redux.action(
  actions.addMaterialReq,
  () => onAddMaterialReq
);
export const addMaterialRes = redux.action(actions.addMaterialRes);

export const addCompanyReq = redux.action(
  actions.addCompanyReq,
  () => onAddCompanyReq
);
export const addCompanyRes = redux.action(actions.addCompanyRes);

export const deleteMaterialReq = redux.action(
  actions.deleteMaterialReq,
  () => onDeleteMaterialReq
);
export const deleteMaterialRes = redux.action(actions.deleteMaterialRes);

export const updateMaterialReq = redux.action(
  actions.updateMaterialReq,
  () => onUpdateMaterialReq
);
export const updateMaterialRes = redux.action(actions.updateMaterialRes);

const onReq = (action, dispatch) => {
  return axios
    .get(`${env.apiPath()}/company/companies/${action.payload}`)
    .then(response => dispatch(res({ data: response.data })))
    .catch(error => dispatch(err({ error })));
};

const onAddCompanyReq = (action, dispatch) => {
  console.log("create company");
  return axios
    .post(`${env.apiPath()}/company/companies`, {
      name: action.payload.name,
      displayName: action.payload.displayName
    })
    .then(response => {
      dispatch(
        notificationCreators.showToast({
          toastType: "success",
          toastMessage: `Company ${action.payload.name} created successfully`
        })
      );
      return response.data;
    })
    .catch(error => {
      dispatch(
        notificationCreators.showToast({
          toastType: "error",
          toastMessage: `Error creating company ${action.payload.name}`
        })
      );
      console.log(error);
    });
};

export const update = payload => {
  return dispatch => {
    return axios
      .put(`${env.apiPath()}/cargo/companies/${payload.id}`, {
        name: payload.name,
        displayName: payload.displayName
      })
      .then(response => {
        dispatch(
          notificationCreators.showToast({
            toastType: "success",
            toastMessage: "Company updated successfully"
          })
        );
        //      dispatch(res({ data: response.data }));
        dispatch(companiesCreators.req());
        dispatch(req(payload.id));
      })
      .catch(error => {
        dispatch(err({ error }));
      });
  };
};

export const deleteTerminal = payload => {
  return dispatch => {
    dispatch(loading(true));
    return axios
      .delete(
        `${env.apiPath()}/cargo/companies/${payload.company}/ports/${
          payload.terminal
        }`
      )
      .then(response => {
        dispatch(req(payload.company));
      })
      .catch(error => dispatch(err({ error })));
  };
};

export const deleteUser = (payload, dispatch) => {
  return dispatch => {
    dispatch(loading(true));
    return axios
      .delete(
        encodeURI(
          `${env.apiPath()}/cargo/companies/${payload.company}/users/${
            payload.user
          }`
        )
      )
      .then(response => {
        dispatch(req(payload.company));
      })
      .catch(error => dispatch(err({ error })));
  };
};

const onAddMaterialReq = (action, dispatch) => {
  return axios
    .post(
      `${env.apiPath()}/cargo/companies/${action.payload.companyId}/ports/${
        action.payload.portId
      }/materials/`,
      { ...action.payload.material }
    )
    .then(response => {
      dispatch(
        notificationCreators.showToast({
          toastType: "success",
          toastMessage: "Material added successfully"
        })
      );
      dispatch(req(action.payload.companyId));
    })
    .catch(error => {
      console.log(error);
    });
};

const onUpdateMaterialReq = (action, dispatch) => {
  return axios
    .put(
      `${env.apiPath()}/cargo/companies/${action.payload.companyId}/ports/${
        action.payload.portId
      }/materials/${action.payload.material.id}/${
        action.payload.material.inboundOrOutbound
      }`,
      { loadOrDischargeRate: action.payload.loadOrDischargeRate }
    )
    .then(response => {
      dispatch(
        notificationCreators.showToast({
          toastType: "success",
          toastMessage: "Material updated successfully"
        })
      );
      dispatch(req(action.payload.companyId));
    })
    .catch(error => {
      console.log(error);
    });
};

const onDeleteMaterialReq = (action, dispatch) => {
  return axios
    .delete(
      `${env.apiPath()}/cargo/companies/${action.payload.companyId}/ports/${
        action.payload.portId
      }/materials/${action.payload.material.id}/${
        action.payload.material.inboundOrOutbound
      }/`
    )
    .then(response => {
      dispatch(
        notificationCreators.showToast({
          toastType: "success",
          toastMessage: "Material deleted"
        })
      );
      dispatch(req(action.payload.companyId));
    })
    .catch(error => {
      console.log(error);
    });
};
