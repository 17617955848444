import React, { MouseEventHandler } from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  onClick?: MouseEventHandler<HTMLHeadingElement>;
  children: any;
}

const Section = (props: Props) => (
  <section className={styles.section}>
    <h2 onClick={props.onClick}>{props.title}</h2>
    {props.children}
  </section>
);

export default Section;
