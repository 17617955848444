import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "../form.module.scss";
import { FetchComponent } from "components/Loading";
import Page from "components/Page";
import Section from "components/Page/Section";
import EditCompanyDetailsForm from "./EditCompanyDetailsForm";
import Ports from "./Ports";
import Users from "./Users";
import Materials from "./Materials";
import { RouteComponentProps, withRouter } from "react-router";
import { Company, CompanyId, Port } from "../../types/company";
import { Fetch, uninitialized } from "../../types/fetch";
import {
  getCompany,
  deleteUser as deleteUserFromServer,
  updateCompany,
  getCompanyUsers,
  exportUrl
} from "../../api/company";
import { deletePort as deletePortFromServer } from "../../api/port";
import { CompanyUser, UserId } from "../../types/user";
import { toast } from "react-toastify";
import classnames from "classnames";
import Dashboards from "./Dashboards";
import { isLocalHost, isProdDomain, isTestDomain } from "../../utils/env";
import { Button } from "../Button";
import formStyles from "../form.module.scss";

interface Params {
  companyId: CompanyId;
}

interface CompanyDetails {
  id: CompanyId;
  name: string;
  displayName: string;
}

const CompanyDetailsComponent: FunctionComponent<RouteComponentProps<
  Params
>> = ({ match, history }: RouteComponentProps<Params>): JSX.Element => {
  const { companyId } = match.params;

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [users, setUsers] = useState<Fetch<CompanyUser[]>>(uninitialized);
  useEffect(() => {
    let mounted = true;
    getCompany(companyId).then(company => {
      if (mounted) setCompany(company);
    });
    getCompanyUsers(companyId).then(users => {
      if (mounted) setUsers(users);
    });
    return () => {
      mounted = false;
    };
  }, [companyId]);

  const deletePort = (port: Port): Promise<void> => {
    return new Promise<void>(resolve => {
      deletePortFromServer(port.id).then(() => {
        resolve();
        reloadCompany();
      });
    });
  };

  function reloadCompany(): void {
    getCompany(companyId).then(setCompany);
  }

  const handleEditCompanyDetails = (payload: CompanyDetails) => {
    updateCompany(payload.id, {
      name: payload.name,
      displayName: payload.displayName
    })
      .then(setCompany)
      .then(() =>
        toast("Company updated successfully", {
          type: "success",
          className: classnames(styles.cargoToast)
        })
      );
  };

  function deleteUser(userId: UserId): Promise<void> {
    return new Promise<void>(resolve =>
      deleteUserFromServer(companyId, userId).then(() => {
        resolve();
        getCompany(companyId).then(setCompany);
      })
    ).then(() => {
      getCompanyUsers(companyId).then(setUsers);
    });
  }

  const navigateTo = (url: string) => {
    history.push(url);
  };

  return FetchComponent(company, company =>
    FetchComponent(users, users => {

      return (
        <Page title={"Company details"} subtitle={company.name}>
          <Section title={"Import / Export"}>
            <Button
              type="button"
              className={formStyles.button}
              onClick={() => {
                const link = document.createElement('a');
                link.href = exportUrl(companyId);
                link.setAttribute('download', '');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              Download company spreadsheet
            </Button>
            <Button
              type="submit"
              className={formStyles.button}
              onClick={() => navigateTo(`/companies/import/${companyId}`)}
            >
              Import company
            </Button>
          </Section>
          <Section title={"Details"}>
            <EditCompanyDetailsForm
              company={company}
              handleEditCompanyDetails={handleEditCompanyDetails}
            />
          </Section>
          <Ports
            history={history}
            companyId={companyId}
            ports={company.ports}
            users={users}
            deletePort={deletePort}
          />
          <Users
            history={history}
            companyId={companyId}
            users={users}
            deleteUser={deleteUser}
          />
          <Section title={"Materials"}>
            <Materials companyId={companyId} materials={company.materials} />
            <br />
            <Button
              type="submit"
              className={formStyles.button}
              onClick={() => navigateTo(`/materials/new/${companyId}`)}
            >
              Add material
            </Button>
          </Section>
          {// analytics is only deployed for test and prod atm.
          // no need to display the links in the other environments since
          // it will cause an error when pressed
          isProdDomain() || isTestDomain() || isLocalHost() ? (
            <Section title={"Dashboards"}>
              <Dashboards
                reload={reloadCompany}
                companyId={companyId}
                dashboards={company.dashboards}
              />
              <br />
              <Button
                type="submit"
                className={formStyles.button}
                onClick={() => navigateTo(`/dashboard/add/${companyId}`)}
              >
                Add dashboard
              </Button>
            </Section>
          ) : (
            <></>
          )}
        </Page>
      );
    })
  );
};
export default withRouter(CompanyDetailsComponent);
