import axios from "axios";
import * as env from "../utils/env";
import { Company, CompanyId, CompanyInput } from "../types/company";
import { CompanyUser, UserId } from "../types/user";
import { expect200 } from "./index";
import { Fetched } from "../types/fetch";
import * as analytics from "../instrumentation";
import { ReadEvent, WriteEvent } from "../instrumentation/analytics";

export function getCompany(companyId: CompanyId): Promise<Fetched<Company>> {
  return expect200(
    axios.get<Company>(`${env.apiPath()}/company/companies/${companyId}`),
    new ReadEvent("getCompany")
  ).then(result => {
    if (result.type === "success") {
      analytics.changeCompany(result.data);
    }
    return result;
  });
}

export function updateCompany(
  id: CompanyId,
  input: CompanyInput
): Promise<Fetched<Company>> {
  return expect200(
    axios.put(`${env.apiPath()}/company/companies/${id}`, input),
    new WriteEvent("updateCompany")
  );
}

export function getCompanyUsers(
  companyId: CompanyId
): Promise<Fetched<CompanyUser[]>> {
  return expect200(
    axios.get<CompanyUser[]>(
      `${env.apiPath()}/cargo/companies/${companyId}/users`
    ),
    new ReadEvent("getCompanyUsers")
  );
}

export function deleteUser(
  companyId: CompanyId,
  userId: UserId
): Promise<Fetched<void>> {
  return expect200(
    axios.delete(
      encodeURI(`${env.apiPath()}/cargo/companies/${companyId}/users/${userId}`)
    ),
    new WriteEvent("deleteUser")
  );
}

export function exportUrl(companyId: CompanyId): string {
  return `${env.apiPath()}/cargo/companies/${companyId}/export`;
}

export function importCompany(
  companyId: CompanyId,
  file: File
): Promise<Fetched<Company>> {
  const formData = new FormData();
  formData.append("import", file);
  return expect200(
    axios.post(
      `${env.apiPath()}/cargo/companies/${companyId}/import`,
      formData
    ),
    new WriteEvent("importCompany")
  );
}
