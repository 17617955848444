import React, { useState } from "react";

import { UserRow } from "./UserRow";
import { CompanyId } from "../../types/company";
import { CompanyUser, UserId } from "../../types/user";
import classnames from "classnames";
import styles from "./styles.module.scss";
import ReactModal from "react-modal";
import Section from "components/Page/Section";
import { Button } from "../Button";
import * as H from "history";
import formStyles from "../form.module.scss";

interface Props {
  history: H.History;
  companyId: CompanyId;
  users: CompanyUser[];
  deleteUser: (user: UserId) => Promise<void>;
}

function Users(props: Props) {
  const { companyId, users, deleteUser } = props;

  const [deletableUser, setDeletableUser] = useState<CompanyUser>();
  const [toggle, setToggle] = useState<boolean>(false);

  const sortedUsers = users.sort((a, b) =>
    a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1
  );

  const navigateTo = (url: string) => {
    props.history.push(url);
  };

  return (
    <Section title={"Users & Permissions"} onClick={() => setToggle(!toggle)}>
      <ul className={classnames(styles.innerRowHeader)}>
        <li>Email</li>
        <li>Name</li>
        <li className={classnames(styles.actionColumn)}>Action</li>
      </ul>
      {sortedUsers.map((user, index) => (
        <UserRow
          key={index}
          user={user}
          deleteUser={setDeletableUser}
          companyId={companyId}
          defaultOpen={toggle}
        />
      ))}
      <br />
      <Button
        type="submit"
        className={formStyles.button}
        onClick={() => navigateTo(`/user/new/${companyId}`)}
      >
        Add user
      </Button>
      <Button
        type="submit"
        className={formStyles.button}
        onClick={() => navigateTo(`/user/api/new/${companyId}`)}
      >
        Add API user
      </Button>
      {deletableUser && (
        <ReactModal
          isOpen={true}
          contentLabel="Delete"
          className={classnames(styles.modal)}
          overlayClassName={classnames(styles.modalOverlay)}
        >
          <p>Are you sure you want to delete user {deletableUser.fullname}?</p>
          <div>
            <Button
              title="Cancel"
              className={classnames(styles.modalButton)}
              onClick={() => setDeletableUser(undefined)}
            >
              Cancel
            </Button>
            <Button
              title="Delete"
              className={classnames(styles.modalButton)}
              onClick={() => {
                if (deletableUser)
                  deleteUser(deletableUser.userid).then(() =>
                    setDeletableUser(undefined)
                  );
              }}
            >
              Delete
            </Button>
          </div>
        </ReactModal>
      )}
    </Section>
  );
}

export default Users;
