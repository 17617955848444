import React from "react";
import styles from "./styles.module.scss";
import { Link, NavLink } from "react-router-dom";
import iconLogo from "assets/icons/logo.svg";
import * as http from "utils/http";
import alertIcon from "assets/icons/alert-circle-yellow.svg";

const Header = () => {
  return (
    <header className={styles.el} id="header">
      <nav className={styles.bar}>
        <h1 className={styles.logo}>
          <Link to="/" tabIndex={-1}>
            <img src={iconLogo} alt="CargoValue" />
          </Link>
        </h1>
        <div className={styles.nav}>
          <NavLink
            to="/companies"
            className={styles.link}
            activeClassName={styles.active}
          >
            Companies
          </NavLink>
          <NavLink
            to="/newcompany"
            className={styles.subLink}
            activeClassName={styles.active}
          >
            Create new company
          </NavLink>
          <NavLink
            to="/users/all"
            className={styles.link}
            activeClassName={styles.active}
          >
            Users
          </NavLink>
          <NavLink
            to="/auditlog"
            className={styles.link}
            activeClassName={styles.active}
          >
            Auditlog
          </NavLink>
          <span onClick={() => http.logout()} className={styles.link}>
            Log out
          </span>
          <span className={styles.alertWrapper}>
            <img src={alertIcon} alt="Update will take 5-10 minutes" />
            Update will take 5-10 minutes
          </span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
