import React from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";

const SplashPage = () => {
  return (
    <div className={classnames(styles.splashPage)}>
      <div>Cargo Value Administration</div>
    </div>
  );
};

export default SplashPage;
