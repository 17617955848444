import React from "react";
import styles from "../form.module.scss";
import { Button } from "components/Button";

class EditCompanyDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.company.name,
      displayName: props.company.displayName,
      id: props.company.id
    };
  }

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  hasChange() {
    const { company } = this.props;
    return (
      this.state.name !== company.name ||
      this.state.displayName !== company.displayName
    );
  }

  render = () => {
    return (
      <form className={styles.inputForm}>
        <label className={styles.label} htmlFor="name">
          Name*
        </label>
        <input
          id="name"
          name="name"
          type="text"
          className={styles.input}
          value={this.state.name}
          onChange={this.handleChange}
        />
        <label className={styles.label} htmlFor="displayName">
          Display name*
        </label>
        <input
          id="displayName"
          name="displayName"
          type="text"
          className={styles.input}
          value={this.state.displayName}
          onChange={this.handleChange}
        />
        <Button
          className={styles.inputButton}
          title="Update"
          disabled={!this.hasChange()}
          onClick={() => this.props.handleEditCompanyDetails(this.state)}
        >
          Update details
        </Button>
      </form>
    );
  };
}

export default EditCompanyDetailsForm;
