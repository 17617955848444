import React, { FunctionComponent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import Loading from "../Loading";
import { RouteComponentProps, withRouter } from "react-router";
import { getPort } from "../../api/port";
import { Fetch, uninitialized } from "../../types/fetch";
import { Company, Port, Terminal } from "../../types/company";
import Section from "../Page/Section";
import { getTerminal, updateTerminal } from "../../api/terminal";
import Berths from "../Berth/Berths";
import { nonEmpty } from "../../utils/stringutil";
import NodeMaterials from "../Material/NodeMaterials";
import { showError } from "../../utils/error";
import { getCompany } from "../../api/company";
import { GlobalBerth } from "../../types/globalberth";
import { getGlobalBerths } from "../../api/globalberth";

interface Params {
  companyId: string;
  companyPortId: string;
  terminalId: string;
}

const EditTerminal: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId, companyPortId, terminalId } = match.params;
  const { handleSubmit, control, watch } = useForm<Terminal>();

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [port, setPort] = useState<Fetch<Port>>(uninitialized);
  const [terminal, setTerminal] = useState<Fetch<Terminal>>(uninitialized);
  const [globalBerths, setGlobalBerths] = useState<Fetch<GlobalBerth[]>>(
    uninitialized
  );

  useEffect(() => {
    getCompany(companyId).then(setCompany);
    getPort(companyPortId).then(setPort);
    getTerminal(terminalId).then(setTerminal);
    getGlobalBerths(companyPortId).then(setGlobalBerths);
  }, [companyId, companyPortId, terminalId]);

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (company.type === "loading") {
    return <Loading />;
  }
  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }

  if (port.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (port.type === "loading") {
    return <Loading />;
  }
  if (port.type === "failure") {
    return <div>Ouch {port.msg}</div>;
  }

  if (terminal.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (terminal.type === "loading") {
    return <Loading />;
  }
  if (terminal.type === "failure") {
    return <div>Ouch {terminal.msg}</div>;
  }

  if (globalBerths.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (globalBerths.type === "loading") {
    return <Loading />;
  }
  if (globalBerths.type === "failure") {
    return <div>Ouch {globalBerths.msg}</div>;
  }

  function reloadTerminal(): void {
    getTerminal(terminalId).then(setTerminal);
  }

  const onSubmit = (state: Terminal) => {
    updateTerminal(terminalId, {
      companyPortId: companyPortId,
      name: state.name
    }).then(t => {
      if (t.type === "success") {
        setTerminal(t);
      } else {
        showError(t.msg);
      }
    });
  };

  const returnToPortPage = () => {
    history.push(`/port/edit/${companyId}/${companyPortId}`);
  };

  const name = watch<"name", string>("name");

  const portName: string = port.data.name;
  const terminalName: string = terminal.data.name;

  const isDisabled: boolean = !nonEmpty(name) || name === terminalName;

  return (
    <div className={styles.content}>
      <h1>
        Edit terminal {terminalName} for port {portName} in company{" "}
        {company.data.name}
      </h1>
      <Section title={"Details"}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
          <label className={styles.label} htmlFor="name">
            Name*
          </label>
          <Controller
            as={<input type="text" className={styles.input} />}
            name="name"
            defaultValue={terminal.data.name}
            control={control}
          />
          <Button
            title="Update"
            type="submit"
            className={styles.inputButton}
            disabled={isDisabled}
          >
            Update
          </Button>
        </form>
      </Section>
      <Section title={"Materials"}>
        <NodeMaterials
          history={history}
          reload={reloadTerminal}
          nodeMaterials={terminal.data.materials}
          company={company.data}
          portId={companyPortId}
          nodeType={"terminal"}
          ownerId={terminalId}
          ownerName={terminal.data.name}
        />
      </Section>
      <Section title={"Berths"}>
        <Berths
          history={history}
          reload={reloadTerminal}
          berths={terminal.data.berths}
          globalBerths={globalBerths.data}
          companyId={companyId}
          companyPortId={companyPortId}
          terminalId={terminalId}
        />
      </Section>
      <br />
      <hr />
      <br />
      <form onSubmit={() => returnToPortPage()} className={styles.inputForm}>
        <Button title="Update" type="submit" className={styles.inputButton}>
          Back
        </Button>
      </form>
    </div>
  );
};

export default withRouter(EditTerminal);
