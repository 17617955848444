export function nonEmpty(s?: string): boolean {
  return s !== null && s !== undefined && s.trim().length !== 0;
}

export function combineToPath(parameters: (string | undefined)[]): string {
  let result = "";
  parameters.forEach(p => {
    if (p !== undefined) {
      result += "/" + p;
    }
  });
  return result;
}
