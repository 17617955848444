import { Fetched } from "../types/fetch";
import axios from "axios";
import * as env from "../utils/env";
import { expect200 } from "./index";
import { GlobalPort } from "../types/globalport";
import { ReadEvent } from "../instrumentation/analytics";

export function getGlobalPorts(): Promise<Fetched<GlobalPort[]>> {
  return expect200(
    axios.get(`${env.apiPath()}/cargo/ports`),
    new ReadEvent("getGlobalPorts")
  );
}
