import React, { useState } from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { PortMaterial } from "../../types/material";
import { CompanyUser, MaterialPermission, Permission } from "../../types/user";
import PermissionComponent from "./PermissionsComponent";

interface PortMaterialProp {
  portMaterial: PortMaterial;
  permissions: { permission: MaterialPermission; user: CompanyUser }[];
}

function PortMaterialComponent(props: PortMaterialProp) {
  const { portMaterial, permissions } = props;
  const [open, setOpen] = useState<boolean>(false);

  const schedulePermission: {
    permission: Permission;
    user: CompanyUser;
  }[] = permissions.map(up => ({
    user: up.user,
    permission: up.permission.schedule.permission
  }));
  const inventoryPermission: {
    permission: Permission;
    user: CompanyUser;
  }[] = permissions.map(up => ({
    user: up.user,
    permission: up.permission.inventory.permission
  }));

  return (
    <>
      <ul
        className={
          open
            ? classnames(styles.materialRow, styles.open)
            : classnames(styles.materialRow)
        }
        onClick={() => setOpen(!open)}
      >
        <li
          className={classnames(styles.indentedRow, styles.inboundOrOutbound)}
        >
          {portMaterial.inboundOrOutbound}
        </li>
        <li />
        <li className={classnames(styles.unIndentedRow)}>
          {portMaterial.material.name}
        </li>
        <li />
        <li />
        <li />
      </ul>
      {open && (
        <div
          className={classnames(styles.materialPermission, styles.indentedRow)}
        >
          {inventoryPermission.length > 0 && (
            <div className={classnames(styles.permissionGroup)}>
              <div>Inventory</div>
              <PermissionComponent userPermissions={inventoryPermission} />
            </div>
          )}
          {schedulePermission.length > 0 && (
            <div className={classnames(styles.permissionGroup)}>
              <div>Schedule</div>
              <PermissionComponent userPermissions={schedulePermission} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

interface Props {
  portMaterials: PortMaterial[];
  materialPermissions: { permission: MaterialPermission; user: CompanyUser }[];
}

function PortMaterialsComponent(props: Props): JSX.Element {
  let filteredMaterials = props.portMaterials.sort((a, b) =>
    a.inboundOrOutbound.toLowerCase() + a.material.name.toLowerCase() >
    b.inboundOrOutbound.toLowerCase() + b.material.name.toLowerCase()
      ? 1
      : -1
  );

  return (
    <>
      {filteredMaterials.map((material, index) => {
        const materialPermission = props.materialPermissions.filter(
          ({ permission }) =>
            permission.id === material.material.id &&
            material.inboundOrOutbound === permission.inboundOrOutbound
        );
        return (
          <PortMaterialComponent
            key={index}
            portMaterial={material}
            permissions={materialPermission}
          />
        );
      })}
    </>
  );
}

export default PortMaterialsComponent;
