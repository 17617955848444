import React, { FunctionComponent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styles from "../form.module.scss";
import { Button } from "components/Button";
import Select from "../Select";
import Loading from "../Loading";
import { OptionsType } from "react-select";
import { RouteComponentProps, withRouter } from "react-router";
import { createPort } from "../../api/port";
import { Fetch, uninitialized } from "../../types/fetch";
import { getCompany } from "../../api/company";
import { Company, CompanyPortId, Port } from "../../types/company";
import { GlobalPort } from "../../types/globalport";
import { getGlobalPorts } from "../../api/globalport";
import { showError } from "../../utils/error";

interface Params {
  companyId: string;
}

const NewPort: FunctionComponent<RouteComponentProps<Params>> = ({
  match,
  history
}: RouteComponentProps<Params>): JSX.Element => {
  const { companyId } = match.params;
  const { register, handleSubmit, control, watch } = useForm<Port>();

  const [company, setCompany] = useState<Fetch<Company>>(uninitialized);
  const [ports, setPorts] = useState<Fetch<GlobalPort[]>>(uninitialized);
  const [portName, setPortName] = useState<string>("");

  useEffect(() => {
    getCompany(companyId).then(setCompany);
    getGlobalPorts().then(setPorts);
  }, [companyId]);

  const portId = watch("portId");

  const isDisabled: boolean = !portId;

  if (company.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (company.type === "loading") {
    return <Loading />;
  }
  if (company.type === "failure") {
    return <div>Ouch {company.msg}</div>;
  }

  if (ports.type === "uninitialized") {
    return <div>Initializing</div>;
  }
  if (ports.type === "loading") {
    return <Loading />;
  }
  if (ports.type === "failure") {
    return <div>Ouch {ports.msg}</div>;
  }

  const onSubmit = (state: Port) => {
    createPort({
      companyId: companyId,
      portId: state.portId,
      displayName: portName
    }).then(port => {
      if (port.type === "success") {
        goToEditPortPage(port.data.id);
      } else {
        showError(port.msg);
      }
    });
  };

  const returnToCompanyPage = () => {
    history.push(`/companies/${companyId}`);
  };

  const goToEditPortPage = (id: CompanyPortId) => {
    history.push(`/port/edit/${companyId}/${id}`);
  };

  const companyName: string = company.data.name;

  type OptionType = {
    value: string;
    label: string;
    name: string;
  };

  const portValues: OptionsType<OptionType> = ports.data
    .filter(p => !company.data.ports.map(cp => cp.portId).includes(p.portId))
    .sort((a, b) => (a.portId > b.portId ? 1 : -1))
    .map(p => ({
      value: p.portId,
      label: p.name + " (" + p.portId + ")",
      name: p.name
    }));

  return (
    <div className={styles.content}>
      <h1>New port for company {companyName}</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.inputForm}>
        <label className={styles.label} htmlFor="portId">
          Port*
        </label>
        <Controller
          render={({ onChange, value, name, ref }) => (
            <Select
              className={styles.select}
              ref={register}
              value={portValues.filter(c => c.value === value)}
              name={name}
              onChange={(opt: OptionType) => {
                onChange(opt.value);
                setPortName(opt.name);
              }}
              options={portValues}
              inputRef={ref}
            />
          )}
          defaultValue={""}
          key="value"
          label="Port*"
          name="portId"
          control={control}
          ref={register}
        />

        <Button
          title="Cancel"
          className={styles.inputButton}
          onClick={returnToCompanyPage}
        >
          Cancel
        </Button>
        <Button
          title="Create"
          type="submit"
          className={styles.inputButton}
          disabled={isDisabled}
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default withRouter(NewPort);
