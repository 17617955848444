import React from "react";
import ReactSelect from "react-select";

const Select = props => (
  <ReactSelect
    {...props}
    styles={{
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "#595c79",
        color: "white",
        borderRadius: "3px",
        border: "1px solid #626674",
        transition: "border-color 0.1s ease",
        lineHeight: 1
      }),
      input: (provided, state) => ({
        ...provided,
        backgroundColor: "#595c79",
        color: "white",
        lineHeight: 1
      }),
      menuList: (provided, state) => ({
        ...provided,
        backgroundColor: "#595c79",
        color: "white",
        lineHeight: 1
      }),
      singleValue: (provided, state) => ({
        ...provided,
        backgroundColor: "#595c79",
        color: "white",
        lineHeight: 1
      })
    }}
    theme={theme => ({
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary25: "595c79",
        primary: "#8f9ad5",
        neutral0: "black"
      },
      lineHeight: 1
    })}
  />
);

export default Select;
