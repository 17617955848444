import { AxiosResponse } from "axios";
import { success, failure, Fetched } from "../types/fetch";
import * as analytics from "../instrumentation";
import { Event as AnalyticsEvent } from "../instrumentation/analytics";

export function expect200<T>(
  p: Promise<AxiosResponse<T>>,
  analyticsEvent?: AnalyticsEvent
): Promise<Fetched<T>> {
  return expect(p, 200, analyticsEvent);
}

export function expect204<T>(
  p: Promise<AxiosResponse<T>>,
  analyticsEvent?: AnalyticsEvent
): Promise<Fetched<T>> {
  return expect(p, 204, analyticsEvent);
}

function expect<T>(
  p: Promise<AxiosResponse<T>>,
  expectedStatus: number,
  analyticsEvent?: AnalyticsEvent
): Promise<Fetched<T>> {
  return p
    .then(r => toFetched(r, expectedStatus, analyticsEvent))
    .catch(e => {
      if (analyticsEvent !== undefined) {
        analytics.event(analyticsEvent, e);
      }
      return failure(`${e}\n\n${e.response.data}`);
    });
}

function toFetched<T>(
  res: AxiosResponse<T>,
  expectedStatus: number,
  analyticsEvent?: AnalyticsEvent
): Fetched<T> {
  if (res.status === expectedStatus) {
    if (analyticsEvent !== undefined) {
      analytics.event(analyticsEvent);
    }
    return success<T>(res.data);
  } else {
    const error = `Expected http status ${expectedStatus}, was ${res.status}.`;
    if (analyticsEvent !== undefined) {
      analytics.event(analyticsEvent, error);
    }
    return failure(error);
  }
}
