import React from "react";
import * as redux from "utils/redux";
import styles from "../form.module.scss";
import classnames from "classnames";
import { withRouter } from "react-router";
import { Button } from "components/Button";
import * as companyCreators from "state/company/creators";
import * as companySelectors from "state/company/selectors";

class NewCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", displayName: "", submitted: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  resetForm = () => {
    this.setState({ name: "", displayName: "" });
  };

  handleSubmit = event => {
    this.setState({ submitted: true });
    this.props.companyCreators.addCompanyReq({ ...this.state });
    this.resetForm();
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  componentDidCatch(error) {
    this.setState({ error });
  }

  validate = (name, displayName) => {
    // true means invalid, so our conditions got reversed
    return {
      name: name.replace(" ", "").length === 0,
      displayName: displayName.replace(" ", "").length === 0
    };
  };

  toggleRow = index => {
    const openRow = this.state.openRow;
    if (index === openRow) {
      this.setState({ openRow: null });
    } else {
      this.setState({ openRow: index });
    }
  };

  render() {
    const errors = this.validate(this.state.name, this.state.displayName);
    const isDisabled = Object.keys(errors).some(x => errors[x]);

    return (
      <div className={classnames(styles.content)}>
        <div className={classnames(styles.inputForm)}>
          <h1>New company</h1>
          <label className={classnames(styles.label)} htmlFor="name">
            Name*
          </label>
          <input
            id="name"
            name="name"
            type="text"
            className={
              this.state.submitted && errors.name
                ? classnames(styles.input, styles.error)
                : classnames(styles.input)
            }
            value={this.state.name}
            onChange={this.handleChange}
          />
          <label className={classnames(styles.label)} htmlFor="displayName">
            Display name*
          </label>
          <input
            id="displayName"
            name="displayName"
            type="text"
            className={
              this.state.submitted && errors.displayName
                ? classnames(styles.input, styles.error)
                : classnames(styles.input)
            }
            value={this.state.displayName}
            onChange={this.handleChange}
          />

          <Button
            title="Cancel"
            className={classnames(styles.inputButton)}
            onClick={this.handleCancel}
          >
            Cancel
          </Button>
          <Button
            title="Create"
            className={classnames(styles.inputButton)}
            onClick={this.handleSubmit}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  redux.connect(
    state => ({
      company: companySelectors.company(state)
    }),
    { companyCreators }
  )(NewCompany)
);
