import "core-js/stable";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import App from "components/App";
import * as appUtils from "state/app/utils";
import * as http from "utils/http";
import "styles/main.scss";
import { init as initInstrumentation } from "instrumentation";

// Initialize instrumentation (woopra)
initInstrumentation();

// Redirect any 401 response to the Auth0 login page.
http.useLogoutInterceptor();

// Throw all response errors as exceptions.
http.useErrorInterceptor();

// Set an XSRF token header on every request.
http.useTokenInterceptor();

// Render the root component.
const el = document.getElementById("root");
ReactModal.setAppElement(el);
ReactDOM.render(appUtils.context(App), el);
