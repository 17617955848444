import React, { FunctionComponent } from "react";
import classnames from "classnames";
import styles from "./styles.module.scss";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";

interface Props {
  company: {
    id: string;
    name: string;
    displayName: string;
    numberOfUsers: number;
  };
  searchValue: string;
}

const CompanyRow: FunctionComponent<Props> = props => (
  <Link to={`/companies/${props.company.id}`}>
    <ul className={styles.row}>
      <li>
        {" "}
        <Highlighter
          highlightClassName={classnames(styles.highlight)}
          searchWords={[props.searchValue]}
          autoEscape={true}
          textToHighlight={props.company.name}
        />
      </li>
      <li>
        {" "}
        <Highlighter
          highlightClassName={classnames(styles.highlight)}
          searchWords={[props.searchValue]}
          autoEscape={true}
          textToHighlight={props.company.displayName}
        />
      </li>
      <li>{props.company.numberOfUsers}</li>
    </ul>
  </Link>
);

export default CompanyRow;
