interface IFetch<T> {
  map: <O>(f: (t: T) => O) => Fetch<O>;
}

export interface Uninitialized<T> extends IFetch<T> {
  type: "uninitialized";
}
export interface Loading<T> extends IFetch<T> {
  type: "loading";
}

export interface Failure<T> extends IFetch<T> {
  type: "failure";
  msg: string;
}
export interface Success<T> extends IFetch<T> {
  type: "success";
  data: T;
}

export const uninitialized: Uninitialized<any> = {
  type: "uninitialized",
  map: (_: any) => uninitialized
};
export const loading: Loading<any> = {
  type: "loading",
  map: (_: any) => loading
};
export function failure<T>(msg: string): Failure<T> {
  return {
    type: "failure",
    map: (_: any) => failure(msg),
    msg
  };
}
export function success<T>(data: T): Success<T> {
  return {
    type: "success",
    map: <O>(f: (t: T) => O) => success(f(data)),
    data
  };
}

export type Fetch<T> = Uninitialized<T> | Loading<T> | Failure<T> | Success<T>;
export type Fetched<T> = Failure<T> | Success<T>;
