import React, { CSSProperties } from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { MouseEventHandler } from "react";

interface Props {
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  isLink?: boolean;
  isSmall?: boolean;
  isSmaller?: boolean;
  isSecondary?: boolean;
  disabled?: boolean;
  isOverlay?: boolean;
  isGreen?: boolean;
  isRed?: boolean;
  className?: string;
  children?: string | HTMLElement[];
  tabIndex?: number;
  title?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Button = (props: Props) => {
  const className = classnames(
    styles.button,
    props.isLoading && styles.loading,
    props.isLink && styles.link,
    props.isSmall && styles.small,
    props.isSmaller && styles.smaller,
    (props.isSecondary || props.disabled) && styles.secondary,
    props.isOverlay && styles.overlay,
    props.isGreen && styles.green,
    props.isRed && styles.red,
    props.className
  );

  return (
    <button
      type={props.type || "button"}
      tabIndex={props.tabIndex}
      disabled={props.disabled || props.isLoading}
      title={props.title}
      style={props.style}
      onClick={props.onClick}
      className={className}
    >
      {props.children}
    </button>
  );
};

export const ButtonGroup = (props: Props) => {
  return (
    <div className={classnames(styles.group, props.className)}>
      {props.children}
    </div>
  );
};
