import { Terminal, CompanyTerminalId, TerminalInput } from "../types/company";
import axios from "axios";
import * as env from "../utils/env";
import { expect200 } from "./index";
import { Fetched } from "../types/fetch";
import { ReadEvent, WriteEvent } from "../instrumentation/analytics";

export function getTerminal(id: CompanyTerminalId): Promise<Fetched<Terminal>> {
  return expect200(
    axios.get(`${env.apiPath()}/company/terminals/${id}`),
    new ReadEvent("getTerminal")
  );
}

export function createTerminal(
  input: TerminalInput
): Promise<Fetched<Terminal>> {
  return expect200(
    axios.post(`${env.apiPath()}/company/terminals`, input),
    new WriteEvent("createTerminal")
  );
}

export function updateTerminal(
  id: CompanyTerminalId,
  input: TerminalInput
): Promise<Fetched<Terminal>> {
  return expect200(
    axios.put(`${env.apiPath()}/company/terminals/${id}`, input),
    new WriteEvent("updateTerminal")
  );
}

export const deleteTerminal = (
  id: CompanyTerminalId
): Promise<Fetched<void>> => {
  return expect200(
    axios.delete(`${env.apiPath()}/company/terminals/${id}`),
    new WriteEvent("deleteTerminal")
  );
};
