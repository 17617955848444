export const isProdDomain = () => {
  return window.location.hostname === "admin.cargovalue.com";
};

export const isTestDomain = () => {
  return window.location.hostname === "cargo-admin.test-kd.net";
};

export const isLocalHost = () => {
  return window.location.hostname === "localhost";
};

export const loginUrl = () => {
  return process.env.NODE_ENV === "production"
    ? "/api/login"
    : `http://${window.location.hostname}:1337/api/login`;
};

export const logoutUrl = () => {
  const apiOrigin =
    process.env.NODE_ENV === "production"
      ? window.location.origin
      : "http://localhost:1337";
  return `${apiOrigin}/api/logout`;
};

export const apiPath = () => {
  return process.env.NODE_ENV === "production" ? "/api" : "/api";
};
