export const isLoading = state => {
  return Boolean(state.permissions.loading);
};

export const isError = state => {
  return Boolean(state.permissions.error);
};

export const permissions = state =>
  state.permissions.loading !== false
    ? null
    : {
        loading: state.permissions.loading,
        terminals: state.permissions.terminals,
        modules: state.permissions.permissions,
        differences: getPermissionDifferences(state.permissions)
      };

const getPermissionDifferences = permissions => {
  const { previousState, ...currentState } = permissions;

  if (!previousState) {
    return null;
  }

  const findMaterialPermission = (materials, name, bound) =>
    materials
      .filter(m => m.name === name && m.inboundOrOutbound === bound)
      .map(m => ({
        schedule: m.schedule,
        inventory: m.inventory
      }))[0] || { schedule: "NONE", inventory: "NONE" };

  const materialDiff = (previous, next) =>
    next.flatMap(nextm => {
      const prev = findMaterialPermission(
        previous,
        nextm.name,
        nextm.inboundOrOutbound
      );
      return nextm.schedule !== prev.schedule ||
        nextm.inventory !== prev.inventory
        ? [
            {
              name: nextm.name,
              inboundOrOutbound: nextm.inboundOrOutbound,
              schedule:
                nextm.schedule === prev.schedule
                  ? undefined
                  : {
                      from: prev.schedule,
                      to: nextm.schedule
                    },
              inventory:
                nextm.inventory === prev.inventory
                  ? undefined
                  : {
                      from: prev.inventory,
                      to: nextm.inventory
                    }
            }
          ]
        : [];
    });

  const findPermission = (permissions, name) =>
    permissions.filter(p => p.name === name).map(p => p.permission)[0] ||
    "NONE";

  const moduleDiff = (previous, next) =>
    next.flatMap(nextp => {
      const prevp = findPermission(previous, nextp.name);

      return nextp.permission !== prevp
        ? [
            {
              name: nextp.name,
              permission: {
                from: prevp,
                to: nextp.permission
              }
            }
          ]
        : [];
    });

  const compareByTerminal = f =>
    currentState.terminals.flatMap(currentTerminal => {
      const prevTerminal = previousState.terminals.find(
        t => t.portId === currentTerminal.portId
      );

      return f(prevTerminal, currentTerminal).map(change => ({
        ...change,
        terminal: currentTerminal.name
      }));
    });

  const changes = {
    moduleChanges: moduleDiff(
      previousState.permissions,
      currentState.permissions
    ),
    terminalChanges: compareByTerminal((a, b) =>
      moduleDiff(a.permissions, b.permissions)
    ),
    materialChanges: compareByTerminal((a, b) =>
      materialDiff(a.materials, b.materials)
    )
  };

  return Object.values(changes).some(c => c.length) ? changes : null;
};
